import React, { useState, useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const LineChart = ({ options, data = [] }) => {
  const chartRef = useRef();
  const [gradientValue, setGradientValue] = useState("");
  useEffect(() => {
    const ctx = chartRef.current.canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, "#fe6145c9");
    gradient.addColorStop(1, "#fec1b60e");
    setGradientValue((prev) => gradient);
  }, []);
  const dataconfig = {};

  let points = [
    {
      title: "Frequency",
      color: "tomato",
    },
    {
      title: "Voltage",
      color: "orange",
    },
    {
      title: "Current",
      color: "green",
    },
    {
      title: "Power",
      color: "dodgerblue",
    },
  ];

  const datasets = [
    { name: "activeenergyln1", color: "#eabd3b" },
    { name: "activeexportkwhl1", color: "#de542c" },
    { name: "activeimportkwhl1", color: "#1de4bd" },
    { name: "activepowerl1", color: "#ea7639" },
    { name: "apparentenergyln1", color: "#1ac9e6" },
    { name: "apparentpowerl1", color: "#af4bce" },
    { name: "appexportkwhl1", color: "#142459" },
    { name: "appimportkwhl1", color: "#820401" },
    { name: "currentln1", color: "#e7e341" },
    { name: "dcuid", color: "dodgerblue" },
    { name: "frequencyl1", color: "#e7e34e" },
    { name: "maxcurrentl1", color: "#e12ee3" },
    { name: "maxvoltagel1", color: "#e7e34e" },
    { name: "minvoltagel1", color: "#382e1" },
    { name: "pfl1", color: "#ead231" },
    { name: "quadrant1kwhl1", color: "#8204ce" },
    { name: "quadrant2kwhl1", color: "#12aecd" },
    { name: "quadrant3kwhl1", color: "#ce2302" },
    { name: "quadrant4kwhl1", color: "#eac214" },
    { name: "reactiveenergyln1", color: "#e7e34e" },
    { name: "reactivepowerl1", color: "#eacde2" },
    { name: "voltageln1", color: "#edeac2" },
  ];
  return (
    <div
      className="pb-2"
      style={{
        height: "100vh",
      }}
    >
      <Bar
        ref={chartRef}
        data={{
          labels: data.map((g, i) => g.pqdatetime),
          datasets: datasets.map((dataset) => {
            return {
              label: dataset.name,
              fill: false,
              backgroundColor: dataset.color,
              borderColor: dataset.color,
              radius: 0,
              borderWidth: 1,
              pointBorderWidth: 1,
              tension: 0.1,
              data: data.map((d) => d[dataset.name]),
            };
          }),
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
            title: {
              display: false,
            },
            tooltip: {
              callbacks: {
                // label: function (tooltipItem, data) {
                //   return numFormtter(parseInt(tooltipItem.parsed.y));
                // },
              },
            },
          },
          scales: {
            x: {
              display: true,
              title: "Time",
              grid: {
                display: false,
              },
            },

            y: {
              display: true,
              grid: {
                display: true,
                color: "#DEDEDE",
                borderDash: [10, 3],
              },
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
