import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utils/token";
import moment from "moment";

const APIHeaders = { Version: "0.1", "content-type": "application/json" };
const baseURL = "https://api.headend.chanels.io/";

const credentials = {
  callerid: window.localStorage.getItem("spiral_user"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const companyAPI = createApi({
  reducerPath: "companyPath",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    headers: APIHeaders,
  }),
  endpoints: (build) => ({
    // create DCU
    getCompanyInfo: build.mutation({
      query: (data) => ({
        url: "/management/getspecificcompanydata",
        method: "POST",
        body: {
          ...credentials,
          companyalias: data,
        },
      }),
    }),
  }),
});

export const { useGetCompanyInfoMutation } = companyAPI;
