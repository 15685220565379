import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../components/Layout";
import SubmeterTable from "../../components/Submeter/Table/SubmeterTable";
import TopNav from "../../components/TopNav";
import { meterController } from "../../Controller/meterController";
import ReactPaginate from "react-paginate";
import { Pagination } from "antd";
import { paginationGood } from "../../HelperFunctions";
import Button from "../../components/Button";
import { color } from "../../constant/color";
import AddSubmeter from "../../components/Submeter/AddSubmeter";
const Submeter = () => {
  const [meter, setMeter] = useState([]);
  const [metermodel, setmetermodel] = useState([]);
  const [companyalais, setcompanyalais] = useState([]);
  const [timezone, settimezone] = useState([]);
  const [activestatus, setactivestatus] = useState("");
  const [modal, setModal] = useState(false);

  const [endsaasexpirationdate, setendsaasexpirationdate] = useState("");
  const [startsaasexpirationdate, setstartsaasexpirationdate] = useState("");
  const [startdatecreated, setstartdatecreated] = useState("");
  const [enddatecreated, setenddatecreated] = useState("");
  const [powerstatus, setpowerstatus] = useState("");
  const [serialid, setserialid] = useState("");

  const [startdateassigned, setstartdateassigned] = useState("");
  const [enddateassigned, setenddateassigned] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [inputname, setinputname] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setloading] = useState(true);

  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setItemsPage] = useState(20);
  const [pageCount, setPageCount] = useState(0);

  // Invoke when user click to request another page

  const [pageNumber, setPageNumber] = useState(20);
  const [pageSize, setPageSize] = useState(1);
  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/devicemanagement/submeter/getallmeteranalytics",
          {
            devicemodel: JSON.stringify(metermodel),
            serialid: JSON.stringify(serialid),
            companyalias: JSON.stringify(companyalais),
            timezone: JSON.stringify(timezone),
            activestatus: activestatus,
            startdatecreated: startdatecreated,
            enddatecreated: enddatecreated,
            startdateassigned: startdateassigned,
            enddateassigned: enddateassigned,
            startsaasexpirationdate: startsaasexpirationdate,
            endsaasexpirationdate: endsaasexpirationdate,
            powerstatus: powerstatus,
          }
        );
        let result = meterController(res);

        if (result.message !== "success") {
          toast.error(result.message);
          return;
          // fix this
        }
        setMeter((prev) => result.data.body);
        setCount((prev) => result.data.meta.count);
        setloading(false);
      } catch (err) {
        toast.error(err.message);
        setloading(false);
      }
    })();
  }, [inputname, refresh]);
  return (
    <Layout>
      <TopNav />
      <div
        className=" mt-3 mx-3 p-2 bg-white shadow rounded-2 "
        style={{
          marginBottom: 200,
        }}
      >
        {modal && <AddSubmeter closeModal={setModal} refreshBtn={setRefresh} />}
        <div className=" row mb-3 d-flex align-items-center justify-content-between">
          <div className="col-3">
            <h3 className="m-0 h5">Submeters</h3>
            <p className="m-0 ">List of submeter you have created</p>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <input
              className="form-input px-2"
              style={{
                outline: "none",
              }}
              placeholder="Search for submeter"
              value={inputname}
              onChange={(e) => setinputname(e.target.value)}
            />
            <div
              className="col-1 mx-2 d-flex align-items-center justify-content-end"
              onClick={() => setModal(true)}
            >
              <div
                className=""
                style={{
                  width: 150,
                }}
              >
                <Button
                  text={"Add Submeter"}
                  bgColor={color.primary}
                  fontSize={"12px"}
                  height={"40px"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" p-2">
          <SubmeterTable
            loading={loading}
            data={meter.filter((i) =>
              i.meterid
                .toLowerCase()
                .trim()
                .includes(inputname.trim().toLowerCase())
            )}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Submeter;
