export const timeZone = [
  {
    id: 1,
    zone: "Mauritius and Seychelles Time",
    value: "GMT+4",
  },
  {
    id: 2,
    zone: "East Africa Time",
    value: "GMT+3",
  },
  {
    id: 3,
    zone: "Central Africa Time",
    value: "GMT+2",
  },
  {
    id: 4,
    zone: "West Africa Time",
    value: "GMT+1",
  },
  {
    id: 5,
    zone: " Cape Verde Time",
    value: "GMT-1",
  },
];
