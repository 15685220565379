import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useEffect, useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";
import { Input, Select } from "antd";
import { meterController } from "../../../Controller/meterController";

const AddSingle = ({ closeBtn, refreshBtn }) => {
  const { Option } = Select;
  const [serialid, setSerialid] = useState("");
  const [meterid, setMeterid] = useState("");
  const [metermodel, setMeterModel] = useState([]);
  const [selectedmodel, setselectedmodel] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState("");
  const [errormsg, seterrormsg] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          `/devicemanagement/submeter/getallmetermodels`,
          {}
        );

        let result = meterController(res);
        if (result.message !== "success") {
          toast.error(result.message);
        }

        setMeterModel(result.data.body.map((i) => i.devicemodel));
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const handleSubmeter = async () => {
    try {
      if (!/^[1-9]/.test(meterid.toString())) {
        seterror("MeterId must start with a numbers between 1 and 9 (not 0)");
        setTimeout(() => {
          seterror(null);
        }, 10000);
        return;
      }

      if (!serialid || !meterid || !selectedmodel)
        return toast.error("All values are required");
      setLoading((prev) => true);
      let res = await axios.post(
        "/devicemanagement/submeter/createsubmetersingle",
        {
          meterid: meterid,
          serialid: serialid,
          metermodel: selectedmodel,
        }
      );
      let result = companyController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading((prev) => false);
        return;
      }

      if (!Object.keys(result.message).length) {
        toast.error("Failed to create submeter");
        setLoading((prev) => false);
        return;
      }

      toast.success("Submeter created successfully");
      setLoading((prev) => false);
      refreshBtn(false);
      closeBtn(false);
    } catch (err) {
      toast.error(err.message);
      setLoading((prev) => false);
    }
  };
  return (
    <div className="backdrop">
      <div className="submeter-add_modal animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-4 modal-title pb-2">
              Fill the input fields to a create a single submeter
            </div>
            <div>
              <div className="" onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>
          {error && (
            <p
              className="text-danger"
              style={{
                fontSize: 13,
              }}
            >
              {error}{" "}
            </p>
          )}

          <div className="row">
            <div className="col-12">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Serial id
                </label>
                <Input
                  size="large"
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="enter serial id..."
                  value={serialid}
                  onChange={(e) => setSerialid(e.target.value)}
                />
              </div>
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Meter id
                </label>
                <Input
                  size="large"
                  // class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="enter a meter id"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }
                  }}
                  maxLength="13"
                  type={"number"}
                  value={meterid}
                  onChange={(e) => setMeterid(e.target.value)}
                />
              </div>

              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Select a meter model
                </label>

                <div className="w-100">
                  <Select
                    className="form-input w-100"
                    placeholder={"select a meter model"}
                    onChange={(e) => setselectedmodel(e)}
                    size="large"
                    showSearch
                  >
                    {metermodel.map((i) => (
                      <Option key={i} value={i}>
                        {i}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="my-4" onClick={() => handleSubmeter()}>
                <Button
                  text={"Create"}
                  color={color.white}
                  bgColor={color.primary}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSingle;
