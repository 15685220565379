import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";

const AddCompany = ({ closeBtn, refreshBtn }) => {
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCompany = async () => {
    try {
      if (!name || !email || !phone || !alias)
        return toast.error("All values are required");
      setLoading((prev) => true);
      let res = await axios.post("/management/createnewcompany", {
        companyname: name,
        companyalias: alias,
        emailaddress: email,
        phone: phone,
      });
      let result = companyController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading((prev) => false);
        return;
      }
      toast.success("Company created successfully");
      refreshBtn((prev) => !prev);
      setLoading((prev) => false);
      closeBtn((prev) => false);
    } catch (err) {
      toast.error(err.message);
    }
  };
  return (
    <div className="backdrop">
      <div className="company-modal animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-4 modal-title pb-2">
              Fill the input fields to create a new company
            </div>
            <div>
              <div onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Name of company
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="company abc..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Short name for company
                </label>
                <input
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="a short name to describe the company"
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength) {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }
                  }}
                  maxLength="5"
                  type={"text"}
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                />
              </div>
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Company mobile phone.
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="0801234567"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Company email address.
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="************@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="my-4" onClick={() => handleCompany()}>
                <Button
                  text={"Create"}
                  color={color.white}
                  bgColor={color.primary}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCompany;
