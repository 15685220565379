import { css } from "@emotion/react";
import { MoonLoader, PulseLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const CustomButton = ({
  className,
  onClick,
  styles,
  children,
  color,
  loading,
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      className={className}
      style={styles}
      {...rest}
    >
      {loading && (
        <span>
          <PulseLoader
            css={override}
            color={color ?? "white"}
            size={5}
            speedMultiplier={1}
          />
        </span>
      )}
      {loading ? "" : children}
    </button>
  );
};
