import axios from "../../../utils/axios";
import { useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import TopNav from "../../../components/TopNav";
import "./style.css";
import toast from "react-hot-toast";
import { meterController } from "../../../Controller/meterController";
import { useEffect, useState } from "react";
import PowerImg from "../../../assets/svg/flash.svg";
import MeterImg from "../../../assets/svg/meterimg.png";
import DateImg from "../../../assets/svg/calender.png";
import Sourceimg from "../../../assets/svg/sources.svg";
import ChipImg from "../../../assets/svg/chip.svg";

const ModelInfo = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let res = await axios.post(
          "/devicemanagement/submeter/getmetermodelinfo",
          {
            metermodel: id,
          }
        );
        let result = meterController(res);
        if (result.message !== "success") {
          toast.error(result.message);
          return;
        }
        await setData((prev) => result.data);
        setLoading((prev) => false);
        return;
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);
  return (
    <Layout>
      <TopNav />
      <div className="px-3 mt-2">
        <div
          className="mt-3 bg-white rounded-2 p-3 shadow"
          style={{
            marginBottom: 200,
          }}
        >
          <div>
            <div className="font-bold">Meter model info</div>
          </div>

          <div className="row d-flex align-items-center justify-content-center ">
            <div className="col-10">
              <div className="row d-flex align-items-center mt-3 model-entry pb-3  ">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                  <div className="d-flex align-items-center ">
                    <div className="model-img">
                      <img
                        src={ChipImg}
                        width={35}
                        height={35}
                        alt="flash image"
                      />
                    </div>
                    <div>
                      <div className="model-title">Model Name</div>
                      <div className="model-desc">
                        The model name assigned when creating the meter model.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <div className="model-value w-100 p-2 rounded-2">
                    <div>{id}</div>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center mt-5 model-entry pb-3 ">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                  <div className="d-flex align-items-center">
                    <div className="model-img">
                      <img
                        src={MeterImg}
                        width={40}
                        height={40}
                        alt="flash image"
                      />
                    </div>
                    <div>
                      <div className="model-title">Phase type</div>
                      <div className="model-desc">
                        The phase type of the meter model
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <div className="model-value w-100 p-2 rounded-2">
                    <div>
                      {loading ? "loading" : !data ? "" : data?.phasetype}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center mt-5 model-entry pb-3 ">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                  <div className="d-flex align-items-center">
                    <div className="model-img">
                      <img
                        src={MeterImg}
                        width={40}
                        height={40}
                        alt="flash image"
                      />
                    </div>
                    <div>
                      <div className="model-title">Device type </div>
                      <div className="model-desc">Device type</div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <div className="model-value w-100 p-2 rounded-2">
                    <div>
                      {loading ? "loading" : !data ? "" : data?.devicetype}{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center mt-5 model-entry pb-3 ">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                  <div className="d-flex align-items-center ">
                    <div className="model-img">
                      <img src={PowerImg} alt="flash image" />
                    </div>
                    <div>
                      <div className="model-title">Power limit</div>
                      <div className="model-desc">
                        The power limit of the meter model
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <div className="model-value w-100 p-2 rounded-2">
                    <div>
                      {loading ? "loading" : !data ? "" : data?.powerlimit}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center mt-5 model-entry pb-3 ">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                  <div className="d-flex align-items-center">
                    <div className="model-img">
                      <img
                        src={DateImg}
                        width={35}
                        height={35}
                        alt="flash image"
                      />
                    </div>
                    <div>
                      <div className="model-title">Date created</div>
                      <div className="model-desc">
                        Date the meter model was created
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <div className="model-value w-100 p-2 rounded-2">
                    <div>
                      {loading ? "loading" : !data ? "" : data?.datecreated}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex align-items-center mt-5 model-entry pb-3 ">
                <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7">
                  <div className="d-flex align-items-center">
                    <div className="model-img">
                      <img
                        src={Sourceimg}
                        width={35}
                        height={35}
                        alt="flash image"
                      />
                    </div>
                    <div>
                      <div className="model-title">Number of sources</div>
                      <div className="model-desc">
                        Number of sources on {!data ? "" : data.devicetype}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <div className="model-value w-100 p-2 rounded-2">
                    <div>
                      {loading ? "loading" : !data ? "" : data?.numberofsources}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="model-entry pb-3 d-flex align-items-center justify-content-between"></div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ModelInfo;
