import * as React from "react";
import "./style.css";
import moment from "moment";
import { Link } from "react-router-dom";
import { color } from "../../constant/color";

const Messagestable = ({ data = [] }) => {
  return (
    <div
      className="w-100"
      style={{
        overflow: "scroll",
      }}
    >
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Thread Workers</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map((i, index) => (
            <tr key={index}>
              <td scope="row">{index + 1}</td>
              <td>{i[0]}</td>
              <td>
                <div
                  style={{
                    color: i[1] == true ? color.green : color.red,
                  }}
                >
                  {i[1] == true ? "active" : "inactive"}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Messagestable;
