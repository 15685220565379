import moment from "moment";
import { Link } from "react-router-dom";
import { color } from "../../../constant/color";
import ReactPaginate from "react-paginate";
import React, { useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import Button from "../../Button";
import "./style.css";
import { flattenArr } from "../../../HelperFunctions";
import CloseIcon from "../../CloseIcon";

const SubmeterTable = ({ data = [], closeModal }) => {
  return (
    <div
      className="submeter-table_modal animate__animated animate__fadeInUp bg-white rounded-3"
      style={{
        overflow: "scroll",
      }}
    >
      <div className="text-center pt-2 pb-4">
        Result of Submeter you just created.
      </div>
      <div
        style={{
          position: "absolute",
          top: 8,
          right: 10,
          cursor: "pointer",
        }}
        onClick={() => closeModal(false)}
      >
        <CloseIcon />
      </div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Submeter Id</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>

        <tbody>
          {data.map((i, index) => (
            <tr key={index}>
              <td scope="row">{index + 1}</td>
              <td>{i.submeterId}</td>
              <td
                style={{
                  color: i.status == "success" ? color.green : color.red,
                }}
              >
                {i.status}
              </td>
              <td>
                <Link
                  to={`/submeter/${i.submeterId}`}
                  className="text-decoration-underline"
                >
                  View info
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubmeterTable;
