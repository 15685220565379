import { MdOutlineClose } from "react-icons/md";
import { color } from "../../constant/color";

const CloseIcon = () => {
  return (
    <div className="p-2 ">
      <MdOutlineClose size={20} color={color.black} />
    </div>
  );
};

export default CloseIcon;
