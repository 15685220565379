import * as React from "react";
import moment from "moment";
import { color } from "../../../constant/color";
import { Link } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import "./style.css";

const MeterModelTable = ({ data, loading }) => {
  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      name: "Model name",
      selector: (row) => row.devicemodel,
      sortable: true,
      center: true,
    },
    {
      name: "Device Type",
      selector: (row) => row.devicetype,
      sortable: true,
      center: true,
    },
    {
      name: "Phasetype",
      selector: (row) => row.phasetype,
      sortable: true,
      center: true,
    },
    {
      name: "Power limit",
      selector: (row) => row.powerlimit,
      sortable: true,
      center: true,
    },
    {
      name: "Date created",
      selector: (row) => moment(row.datecreated).format("lll"),
      sortable: true,
      grow: 3,
      center: true,
    },
    {
      name: "Channels",
      selector: (row) => row.numberofchannels,
      sortable: true,
      center: true,
    },
    {
      name: "Sources",
      selector: (row) => row.numberofsources,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Link
          style={{ fontSize: "14px" }}
          className="text-decoration-underline"
          to={`/metermodel/${row.devicemodel}`}
        >
          View
        </Link>
      ),
      button: true,
    },
  ];

  return (
    <div className="table-responsive">
      <DataTable
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
      />
    </div>
  );
};

export default MeterModelTable;
