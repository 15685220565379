export const data = [
  {
    title: "Create Single Submeter",
    desc: `Choose this option to create a single submeter. `,
    id: 1,
    btn: "",
  },
  {
    title: "Create and Issue Single Submeter",
    desc: `Select this option to to create and issue a single submeter to company.`,
    id: 2,
    btn: "",
  },
  {
    title: "Create Multiple Submeter",
    desc: `You can select this option when you intend to create multiple submeters. `,
    id: 3,
    btn: "",
  },
  {
    title: "Create and Issue Multiple Submeter",
    desc: `Use this option to create and Issue multiple submeter to a company.`,
    id: 4,
    btn: "",
  },
];
