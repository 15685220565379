import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "axios";
import { companyController } from "../../../Controller/CompanyController";
import { getToken } from "../../../utils/token";

const ServerModal = ({ closeBtn }) => {
  const [password, setpassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePower = async () => {
    try {
      setLoading((prev) => true);
      let user = await getToken("spiral_user");
      let token = await getToken("spiral_token");
      if (!password) {
        toast.warn("Please enter your password");
        setLoading((prev) => false);
        return;
      }
      let res = await axios.post(
        "https://api.headend.spiral.systems/auth/shadowlogin",
        {
          username: user,
          password: password,
        }
      );

      if (res.data.response == "success") {
        let res1 = await axios.post(
          "https://api.headend.spiral.systems/management/gracefulshutdownsequence",
          {
            callerid: user,
            sessionid: token,
          }
        );
        let result = companyController(res1);
        if (result.type !== "success") {
          toast.error(result.message, {
            toastId: "384809lm",
          });
          setLoading((prev) => false);
          return;
        }
        toast.success("All headend process are being shutdown", {
          toastId: "34634fw1",
        });
      } else {
        toast.error(res.data);
      }
      setLoading(false);

      // setLoading((prev) => false);
      // return;
    } catch (err) {
      if (err.response.data) {
        toast.error(err.response.data.response, {
          toastId: "32wiodnc",
        });
        return;
      }
      toast.error(err.message);
    }
  };

  return (
    <div className="backdrop">
      <div className="server-modal animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-3 modal-title pb-2">
              Please provide your credentials to complete this action.
            </div>
            <div>
              <div onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mb-3 company-entry">
                <label class="form-label">Enter your password </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="*************..."
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
              </div>

              <div className="my-4" onClick={() => handlePower()}>
                <Button
                  text={"Continue"}
                  color={color.white}
                  bgColor={color.red}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerModal;
