import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { css } from "@emotion/react";
import "./style.css";

function Button({
  hover,
  width,
  color,
  bgColor,
  height,
  fontWeight,
  fontSize,
  status,
  text,
  mt,
  ml,
  mb,
  mr,
  pt,
  textTransform,
  border,
  showBoxShadow,
  loaderColor,
  loaderHeight,
}) {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <button
      className="custom-button"
      style={{
        width: width || "100%",
        height: height || "100%",
        background: bgColor || "#006fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: mt,
        marginLeft: ml,
        marginBottom: mb,
        marginRight: mr,
        border: border,
        borderRadius: "5px",
        padding: "7px 9px",
        transition: "all 50ms ease",
        boxShadow: showBoxShadow ? "0 5px 10px #eeeeeede" : null,
        cursor: "pointer",
      }}
      disabled={status}
    >
      {status ? (
        <ScaleLoader
          color={loaderColor || "#ffffff"}
          loading={status}
          css={override}
          height={15 || loaderHeight}
        />
      ) : (
        <div
          style={{
            color: color || "#fff",
            textTransform: textTransform || "none",
            fontSize: fontSize || "14px",
            fontWeight: fontWeight || 400,
          }}
        >
          {text}
        </div>
      )}
    </button>
  );
}

export default Button;
