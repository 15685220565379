import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";
import { Input, Select, Radio } from "antd";

const AddModel = ({ closeBtn, refreshBtn }) => {
  const [phasetype, setPhaseType] = useState("");
  const [numberofchannels, setNumberOfChannels] = useState("");
  const [powerLimit, setPowerLimit] = useState("");
  const [meterModel, setMeterModel] = useState("");
  const [error, setError] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputSources, setInputSources] = useState(0);
  const { Option } = Select;
  const [value, setValue] = useState("");
  const onChange = (e) => {
    // console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const handleModel = async () => {
    try {
      const meterModelPattern = /^(.*)-(XG|RF)$/; // Regex to check if meterModel ends with -XG or -RF

      if (
        !deviceType ||
        !phasetype ||
        !inputSources ||
        !numberofchannels ||
        !meterModel ||
        !powerLimit
      ) {
        return toast.error("All values are required");
      }

      if (!value) return toast.error("Select model type");

      // if (!meterModelPattern.test(meterModel)) {
      //   return toast.error('Meter model must end with "-XG" or "-RF"');
      // }

      setLoading(true);

      let res = await axios.post(
        `/devicemanagement/submeter/createmetermodel`,
        {
          metermodel: meterModel + "-" + value,
          phasetype: phasetype,
          numberofchannels: numberofchannels,
          powerlimit: powerLimit,
          devicetype: deviceType,
          numberofsources: inputSources,
        }
      );
      let result = companyController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading((prev) => false);
        return;
      }
      toast.success("Meter model added successfully");
      refreshBtn((prev) => !prev);
      setLoading((prev) => false);
      closeBtn((prev) => false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
  return (
    <div className="backdrop">
      <div className="meter-modal__cover animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-2 modal-title pb-2">
              Fill the input fields to create a meter model
            </div>
            <div>
              <div onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>
          <div class="mb-3 company-entry">
            <div className="">
              <label className="block mb-1" class="form-label">
                Select model type
              </label>
            </div>

            <Radio.Group onChange={onChange} className="block" value={value}>
              <Radio value={"RF"}>RF</Radio>
              <Radio value={"XG"}>SIM</Radio>
            </Radio.Group>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Model name
                </label>
                <Input
                  size="large"
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={meterModel}
                  onChange={(e) => setMeterModel(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Phase type
                </label>
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  placeholder={"single or three"}
                  onChange={(e) => setPhaseType((prev) => e)}
                >
                  {["single", "three"].map((item, i) => (
                    <Option key={i} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Number of channel
                </label>
                <Input
                  size="large"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="number of channels"
                  value={numberofchannels}
                  onChange={(e) => setNumberOfChannels(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Power limit
                </label>
                <Input
                  size="large"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={powerLimit}
                  onChange={(e) => setPowerLimit(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Number of sources
                </label>
                <Input
                  size="large"
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  value={inputSources}
                  onChange={(e) => setInputSources(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-6 col-xxl-6">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Meter type.
                </label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  showSearch
                  size="large"
                  onChange={(e) => setDeviceType((prev) => e)}
                >
                  {["Submeter", "Panel"].map((item, i) => (
                    <Option key={i} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-conten">
              <div className="my-4 col-3" onClick={() => handleModel()}>
                <Button
                  text={"Create"}
                  color={color.white}
                  bgColor={color.primary}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModel;
