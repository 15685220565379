import React from "react";
import { HiHome } from "react-icons/hi";
import { color } from "../../constant/color";
import { RiBuilding4Fill } from "react-icons/ri";

const PageBar = ({ title, page }) => {
  return (
    <div className="d-flex align-items-center justify-content-between shadow-sm rounde-2 bg-white mt-3">
      <div>
        <div className="d-flex p-2 align-items-center">
          <div>
            {page == "dashboard" && <HiHome size={20} color={"#202122"} />}
            {page == "company" && (
              <RiBuilding4Fill size={20} color={"#202122"} />
            )}
          </div>
          <div className="mx-2">{title}</div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default PageBar;
