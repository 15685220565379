import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const QueuesLineChart = ({ options, data }) => {
  const dataconfig = {};

  let points = [
    {
      title: "Frequency",
      color: "tomato",
    },
    {
      title: "Voltage",
      color: "orange",
    },
    {
      title: "Current",
      color: "green",
    },
    {
      title: "Power",
      color: "dodgerblue",
    },
  ];
  return (
    <div
      className="pb-2"
      style={{
        height: "80vh",
      }}
    >
      <Bar
        data={{
          labels: Array.from(Array(20)).map((i) =>
            Math.floor(Math.random() * 100 + 1)
          ),
          datasets: [
            {
              label: "Queue",
              fill: false,
              backgroundColor: "orange",
              data: Array.from(Array(20)).map((i) =>
                Math.floor(Math.random() * 100 + 1)
              ),
              radius: 1,
              pointBorderWidth: 1,
              tension: 0.4,
              borderWidth: 1,
              borderColor: "orange",
              barThickness: 15,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
            title: {
              display: true,
              text: "Queue Capacity History",
              font: {
                size: 16,
                weight: 400,
              },
              position: "top",
            },
            tooltip: {
              callbacks: {
                // label: function (tooltipItem, data) {
                //   return numFormtter(parseInt(tooltipItem.parsed.y));
                // },
              },
            },
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
              },
            },

            y: {
              display: true,
              grid: {
                display: true,
                color: "#DEDEDE",
                borderDash: [10, 3],
              },
            },
          },
        }}
      />
    </div>
  );
};

export default QueuesLineChart;
