import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../utils/token";
import moment from "moment";

const APIHeaders = { Version: "0.1", "content-type": "application/json" };
const baseURL = "https://api.headend.chanels.io/";

const credentials = {
  callerid: window.localStorage.getItem("spiral_user"),
  sessionid: window.localStorage.getItem("spiral_token"),
};

export const dcuModelAPI = createApi({
  reducerPath: "dcuModelPath",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    headers: APIHeaders,
  }),
  endpoints: (build) => ({
    // create DCU model
    createDCUModel: build.mutation({
      query: (data) => ({
        url: "devicemanagement/dcu/createdcumodel",
        method: "POST",
        body: {
          ...credentials,
          dcumodel: data?.dcumodel,
          devicecapacity: data?.devicecapacity,
        },
      }),
    }),
    deleteDCUModel: build.mutation({
      query: (data) => ({
        url: "devicemanagement/dcu/deletedcumodel",
        method: "POST",
        body: {
          ...credentials,
          dcumodel: data,
        },
      }),
    }),
    // get DCU model
    getDCUModels: build.mutation({
      query: () => ({
        url: "devicemanagement/dcu/getalldcumodels",
        method: "POST",
        body: {
          ...credentials,
        },
      }),
    }),
  }),
});

export const {
  useCreateDCUModelMutation,
  useGetDCUModelsMutation,
  useDeleteDCUModelMutation,
} = dcuModelAPI;
