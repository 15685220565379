import moment from "moment";
import { Link } from "react-router-dom";
import { color } from "../../../constant/color";

const AnaltyicsTable = ({ data = [] }) => {
  return (
    <div
      className="w-100"
      style={{
        overflow: "scroll",
        height: 600,
      }}
    >
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Meter Id</th>
            <th scope="col">DCU Id</th>
            <th scope="col">Active enenrgy </th>
            <th scope="col">Active exportkwhl1</th>
            <th scope="col">Activeimportkwhl1</th>
            <th scope="col">Active powerl1</th>
            <th scope="col">Apparent energyln1</th>
            <th scope="col">Apparent power</th>
            <th scope="col">Appexport kwhl1</th>
            <th scope="col">Currentln1</th>
            <th scope="col">Frequencyl1</th>
            <th scope="col">Max currentl1</th>
            <th scope="col">Max voltagel1</th>
            <th scope="col">Min voltagel1</th>
            <th scope="col">Power factorl1</th>
            <th scope="col">Quadrant1 kwhl1</th>
            <th scope="col">Quadrant2 kwhl1</th>
            <th scope="col">Quadrant3 kwhl1</th>
            <th scope="col">Reactive energyln1</th>
            <th scope="col">Reactive powerl1</th>
            <th scope="col">Voltage ln1</th>
            <th scope="col">Pq datetime</th>
          </tr>
        </thead>
        <tbody>
          {data.map((i, index) => (
            <tr key={index}>
              <td scope="row">{index + 1}</td>
              <td>{i.meterid}</td>
              <td>{i.dcuid}</td>
              <td>{i.activeenergyln1}</td>
              <td>{i.activeexportkwhl1}</td>
              <td>{i.activeimportkwhl1}</td>
              <td>{i.activepowerl1}</td>
              <td>{i.apparentenergyln1}</td>
              <td>{i.apparentpower}</td>
              <td>{i.appexportkwhl1}</td>
              <td>{i.currentln1}</td>
              <td>{i.frequencyl1}</td>
              <td>{i.maxcurrentl1}</td>
              <td>{i.maxvoltagel1}</td>
              <td>{i.minvoltagel1}</td>
              <td>{i.pfl1}</td>
              <td>{i.quadrant1kwhl1}</td>
              <td>{i.quadrant2kwhl1}</td>
              <td>{i.quadrant3kwhl1}</td>
              <td>{i.reactiveenergyln1}</td>
              <td>{i.reactivepowerl1}</td>
              <td>{i.voltageln1}</td>
              <td>{i.pqdatetime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AnaltyicsTable;
