import { configureStore } from "@reduxjs/toolkit";
import { devicesAPI } from "../services/device.services";
import { setupListeners } from "@reduxjs/toolkit/query";
import { companyAPI } from "../services/company.services";
import { dcuModelAPI } from "../services/dcumodel.services";

export const store = configureStore({
  reducer: {
    [devicesAPI.reducerPath]: devicesAPI.reducer,
    [companyAPI.reducerPath]: companyAPI.reducer,
    [dcuModelAPI.reducerPath]: dcuModelAPI.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      devicesAPI.middleware,
      companyAPI.middleware,
      dcuModelAPI.middleware
    );
  },
});

setupListeners(store.dispatch);
