import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Button from "../../components/Button";
import CompnaiesTable from "../../components/Companies/Tables/Companies";
import TopCards from "../../components/Companies/TopCards";
import Layout from "../../components/Layout";
import TopNav from "../../components/TopNav";
import { color } from "../../constant/color";
import axios from "../../utils/axios";
import { companyController } from "../../Controller/CompanyController";
import "./style.css";
import AddCompany from "../../components/Companies/AddCompany";

const Company = () => {
  const [tabstate, settabstate] = useState(1);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [datainactive, setDataInactive] = useState([]);
  const [dataActive, setDataActive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countall, setCountall] = useState(0);
  const [countinactive, setCountInactive] = useState(0);
  const [countActive, setCountActive] = useState(0);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/management/getallcompanydata", {});
        let result = companyController(res);

        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setData(result.message.body);
        setCountall(result.message.meta.count);
      } catch (err) {
        toast.err(err.message);
      }
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      try {
        let inactiveCompany = await axios.post(
          "/management/getallinactivecompanydata",
          {}
        );
        let result = companyController(inactiveCompany);

        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }

        setDataInactive(
          result.message.body.map((i) => {
            return {
              ...i,
              activestatus: "inactive",
            };
          })
        );
        setCountInactive(result.message.meta.count);
      } catch (err) {
        toast.err(err.message);
      }
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      try {
        let activeCompany = await axios.post(
          "/management/getallactivecompanydata",
          {}
        );
        let result = companyController(activeCompany);

        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }

        setDataActive(
          result.message.body.map((i) => {
            return {
              ...i,
              activestatus: "active",
            };
          })
        );
        setCountActive(result.message.meta.count);
      } catch (err) {
        toast.err(err.message);
      }
    })();
  }, [refresh]);
  return (
    <Layout>
      <TopNav />
      <div className="mt-5 px-3">
        {modal && <AddCompany closeBtn={setModal} refreshBtn={setRefresh} />}
        <div
          className="container-fluid"
          style={{
            marginBottom: 200,
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                title={"All companies"}
                iconType={"company"}
                value={countall}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                title={"Active companies"}
                iconType={"activecompany"}
                value={countActive}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                title={"Inactive companies"}
                iconType={"inactivecompany"}
                value={countinactive}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-end justify-content-end">
              <div className="rounded" onClick={() => setModal(true)}>
                <Button
                  text={"Add company"}
                  color={color.white}
                  fontSize={"15px"}
                  bgColor={color.primary}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 border bg-white shadow-sm rounded-2">
            <div className="d-flex company-tab__cover">
              <div
                className="company-tab py-3"
                style={{
                  color: tabstate == 1 ? color.primary : "#a9a9a9",
                }}
                onClick={() => settabstate(1)}
              >
                All companies
              </div>
              <div
                className="company-tab mx-1 px-4 py-3"
                style={{
                  color: tabstate == 2 ? color.primary : "#a9a9a9",
                }}
                onClick={() => settabstate(2)}
              >
                Active companies
              </div>
              <div
                className="company-tab mx-1 px-4 py-3"
                style={{
                  color: tabstate == 3 ? color.primary : "#a9a9a9",
                }}
                onClick={() => settabstate(3)}
              >
                Inactive companies
              </div>
            </div>
            {tabstate == 1 && (
              <div className="col-12">
                <CompnaiesTable data={data} />
              </div>
            )}
            {tabstate == 2 && (
              <div className="col-12">
                <CompnaiesTable data={dataActive} />
              </div>
            )}
            {tabstate == 3 && (
              <div className="col-12">
                <CompnaiesTable data={datainactive} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Company;
