import { useState } from "react";
import CloseIcon from "../../CloseIcon";
import AddSingle from "../AddSingle";
import { data } from "./data";
import "./style.css";
import { Select } from "antd";
import { GiMicrochip } from "react-icons/gi";
import { color } from "../../../constant/color";
import AddMultiple from "../AddMultiple";
import AddSingleAndIssue from "../AddSingleAndIssue";
import MeterImg from "../../../assets/svg/chip.svg";
import AddMultipleAndIsssue from "../AddMultipleAndIsssue";
import moment from "moment";

const SubmeterInfoModal = ({ closeModal, model, company, date }) => {
  const [type, setType] = useState(false);
  const [removemodal, setRemoveModal] = useState(false);
  const Card = ({ item }) => {
    return (
      <div className="addsubmeter-cover p-3 shadow-sm rounded-3 bg-white flex-column d-flex align-items-start justify-content-start">
        <div
          className="addsubmeter-icon rounded-2  d-flex align-items-center justify-content-center"
          style={{}}
        >
          <div className="">
            <GiMicrochip size={35} color={color.primary} />
          </div>
        </div>
        <div className="mt-2">
          <div className="addsubmeter-title">{item.title}</div>
          <div className="addsubmeter-desc">{item.desc}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="backdrop">
      <div
        className="submeter-info_modal p-3 animate__animated animate__fadeInUp bg-white rounded-3"
        style={{
          display: removemodal ? "none" : "block",
        }}
      >
        <div className="text-center fw-bold mb-4">
          Additional information of Submeter
        </div>
        <div
          style={{
            position: "absolute",
            top: "8px",
            right: 20,
          }}
          onClick={() => closeModal(false)}
        >
          <CloseIcon />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="submeter-entry pb-1">
                <div className="d-flex align-items-center">
                  <div className="submeter-cover d-flex justify-content-center align-items-center">
                    <img
                      src={MeterImg}
                      className="submeter-img"
                      width={"100%"}
                      height={"100%"}
                      alt="image"
                    />
                  </div>
                  <div className="submeter-modal__title px-3">
                    {" "}
                    Submeter model Info
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Modal number</div>
                <div className="submeter-modal_value">
                  {model == null ? "" : model.metermodel}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Number of channels</div>
                <div className="submeter-modal_value">
                  {model == null ? "" : model.numberofchannels}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Power limit</div>
                <div className="submeter-modal_value">
                  {model == null ? "" : model.powerlimit}Watts
                </div>
              </div>{" "}
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Phase type</div>
                <div className="submeter-modal_value">
                  {model == null ? "" : model.phasetype}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Device type</div>
                <div className="submeter-modal_value">
                  {model == null ? "" : model.devicetype}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Number of sources</div>
                <div className="submeter-modal_value">
                  {model == null ? "" : model.numberofsources}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-12 col-md-12">
              <div className="submeter-entry pb-1">
                <div className="d-flex align-items-center">
                  <div className="submeter-cover d-flex justify-content-center align-items-center">
                    <img
                      src={MeterImg}
                      className="submeter-img"
                      width={"100%"}
                      height={"100%"}
                      alt="image"
                    />
                  </div>
                  <div className="submeter-modal__title px-3">Company Info</div>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Company alias</div>
                <div className="submeter-modal_value">
                  {company == null ? "Not assigned yet" : company.companyalias}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Company Name</div>
                <div className="submeter-modal_value">
                  {company == null ? "Not assigned yet" : company.companyname}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 py-2">
                <div className="submeter-modal_title">Date assigned</div>
                <div className="submeter-modal_value">
                  {date == null
                    ? "Not assigned yet"
                    : moment(date).format("lll")}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmeterInfoModal;
