import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { color } from "../../../constant/color";
import { validateEmail } from "../../../HelperFunctions";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import AuthImage from "../../../assets/images/homebg.png";
import { setToken } from "../../../utils/token";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const [emailerror, setemailerror] = useState(false);
  const [eye, setEye] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);
      if (!username && !password) {
        toast.error("Please enter both values");
        setLoading(false);
        return;
      }
      let res = await axios.post(
        // "http://192.168.1.68:8080/auth/login",
        "https://api.headend.chanels.io/public/auth/login",

        {
          username: username,
          password: password,
        },
        {
          headers: {
            Version: "0.1",
          },
        }
      );
      setLoading(false);
      toast.success("Logged in successfully.");
      await setToken("spiral_token", res.data.sessionid);
      await setToken("spiral_user", username);
      window.location.pathname = "/";
      return;
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.response);
        setLoading(false);
        return;
      }

      toast.error(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="auth-screen w-100 h-100 bg ">
      <div className="container-fluid p-0 m-0">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-sm-12 col-md-5 col-lg-5  col-xl-5 col-xxl-5">
            <div className="form-cover d-flex align-items-center justify-content-center ">
              <div className="form-content bg-white rounded-3 p-4">
                <div className="form-title pt-2 text-center">
                  Chanels headend
                </div>
                <hr />
                <div className="d-none form-title pt-2 text-center">
                  Login to continue
                </div>
                <div className="form-caption mb-4 d-none pt-2">
                  Fill the form to continue.
                </div>
                <div className="mb-3">
                  <label className="form-label">Username</label>
                  <input
                    type="text"
                    className={"border rounded-3 form-input p-1 w-100"}
                    style={{
                      height: 45,
                    }}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    // onInput={(e) => {
                    //   if (!validateEmail(e.target.value)) {
                    //     setemailerror(true);
                    //   } else {
                    //     setemailerror(false);
                    //   }
                    // }}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <div
                    id="emailHelp"
                    className="form-text text-dange d-none fw-300"
                  >
                    {emailerror ? "Please enter a valid email" : ""}
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <div
                    id="exampleInputPassword1"
                    className="w-100 form-control password-cover d-flex align-items-center justify-content-between"
                  >
                    <input
                      type={eye ? "text" : "password"}
                      className="w-75 password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder="******** your password"
                    />
                    <div
                      className="eye-icon p-1"
                      onClick={() => setEye((prev) => !prev)}
                    >
                      {eye ? (
                        <VscEyeClosed size={20} color={"grey"} />
                      ) : (
                        <VscEye size={20} color={"grey"} />
                      )}
                    </div>
                  </div>
                </div>
                {/* <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    /> */}
                {/* <label className="form-check-label">Remember me</label> */}
                <div className="d-none">
                  <a href="http://">Forgot password</a>
                </div>
                <div className="mt-4" onClick={handleLogin}>
                  <Button
                    text={"Sign in"}
                    color={color.white}
                    bgColor={color.primary}
                    status={loading}
                    height={"44px"}
                  />
                  {/* <Link
                    to={"/dashboard"}
                    type="submit"
                    className="btn mb-4 auth-btn w-100 btn-lg btn-primary"
                  >
                    Sign In
                  </Link> */}
                  <button
                    type="submit"
                    className="btn d-none  auth-btn mt-2 border border-primary text-primary w-100 btn-lg btn-white"
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
