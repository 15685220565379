import axios from "../../../utils/axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import ChannelsTable from "../../../components/Submeter/Table/ChannelsTable";
import TopCards from "../../../components/Submeter/TopCards";
import TopNav from "../../../components/TopNav";
import { color } from "../../../constant/color";
import { meterController } from "../../../Controller/meterController";
import "./style.css";
import IssueSubmeter from "../../../components/Submeter/IssueSubmeter";
import moment from "moment";
import StatusModal from "../../../components/Submeter/StatusModal";
import SubmeterInfoModal from "../../../components/Submeter/SubmeterInfoModal";
import UpdateSaas from "../../../components/Submeter/UpdateSaas";

const SubmeterInfo = () => {
  let { id } = useParams();
  const [submeterDetails, setSubMeterDetails] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [modal, setModal] = useState(false);
  const [info, setinfo] = useState(false);
  const [loading, setloading] = useState(false);
  const [status, setstatus] = useState(false);
  const [opensaas, setopensaas] = useState(false);
  const [metainfo, setmetainfo] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/devicemanagement/submeter/getsubmeterinfo",
          {
            meterid: id,
          }
        );
        let result = meterController(res);
        if (result.message !== "success") {
          toast.error(result.message, {
            toastId: "24fw89dcixbvjwnkd",
          });
          return;
        }
        setSubMeterDetails((prev) => result.data);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [refresh]);

  return (
    <Layout>
      <TopNav />
      {modal && (
        <IssueSubmeter
          meterid={id}
          closeBtn={setModal}
          refreshBtn={setRefresh}
        />
      )}

      {opensaas && (
        <UpdateSaas
          closeBtn={setopensaas}
          refreshBtn={setRefresh}
          meter={submeterDetails}
        />
      )}

      {status && (
        <StatusModal
          closeBtn={setstatus}
          refreshBtn={setRefresh}
          meterid={id}
          type={submeterDetails.activestatus}
        />
      )}

      {info && (
        <SubmeterInfoModal
          closeModal={setinfo}
          date={
            submeterDetails.dateassigned ? submeterDetails.dateassigned : null
          }
          model={
            submeterDetails.metermodelinfo
              ? submeterDetails.metermodelinfo
              : null
          }
          company={
            submeterDetails.companyinfo ? submeterDetails.companyinfo : null
          }
        />
      )}
      <div
        className="px-3 mt-4"
        style={{
          marginBottom: 200,
        }}
      >
        <div className="container-fluid">
          <div className="row d-flex align-items-center justify-content-end my-3">
            <div className="col-2">
              <div
                className="p-2 rounded-2"
                onClick={() => setstatus(true)}
                style={{
                  background:
                    submeterDetails == null
                      ? "#ff634719"
                      : submeterDetails.activestatus == "active"
                      ? "#ff634719"
                      : "#5cd95c22",
                  cursor: "pointer",
                }}
              >
                <div
                  className="text-center"
                  style={{
                    fontSize: "13px",
                    color:
                      submeterDetails == null
                        ? color.red
                        : submeterDetails.activestatus == "active"
                        ? color.red
                        : color.green,
                  }}
                >
                  {submeterDetails == null
                    ? "fetching state"
                    : submeterDetails.activestatus == "active"
                    ? "Deactivate"
                    : "Activate"}
                </div>
              </div>
            </div>
            <div className="col-2">
              <div
                className="p-2 rounded-2"
                style={{}}
                onClick={() => setModal(true)}
              >
                <Button text={"Assign submeter"} />
              </div>
            </div>
            <div className="col-2">
              <div
                className="p-2 text-center text-primary rounded-2"
                style={{
                  border: `1px solid ${color.primary}`,
                  fontSize: 13,
                  height: 39,
                  cursor: "pointer",
                }}
                onClick={() => setinfo(true)}
              >
                Submeter Info
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                textcolor={
                  submeterDetails == null
                    ? color.red
                    : submeterDetails.activestatus == "active"
                    ? color.green
                    : color.red
                }
                showCompanyInfo={true}
                bgColor={"company"}
                iconType={"company"}
                title={"Submeter active status"}
                active={true}
                value={
                  submeterDetails == null
                    ? "Inactive"
                    : submeterDetails.activestatus == "active"
                    ? "Submeter is active"
                    : "Inactive"
                }
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                showCompanyInfo={true}
                bgColor={"company"}
                iconType={"company"}
                title={"Submeter expiration date"}
                active={true}
                fontSize={"14px"}
                value={
                  submeterDetails == null
                    ? "Submeter is inactive"
                    : submeterDetails.saasexpirydate
                    ? moment(submeterDetails.saasexpirydate).format("lll")
                    : "Not issued to a company"
                }
                inactive={38}
                onclick={() => setopensaas(true)}
                showBtn
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                showCompanyInfo={true}
                bgColor={"company"}
                iconType={"company"}
                title={"Submeter time zone"}
                active={true}
                fontSize={"14px"}
                value={
                  submeterDetails == null
                    ? "Not issued to a company"
                    : submeterDetails.time_zone
                    ? submeterDetails.time_zone
                    : "Not issued to a company"
                }
                inactive={38}
                showBtn
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                showCompanyInfo={true}
                bgColor={"company"}
                iconType={"company"}
                fontSize={"14px"}
                title={"Date created"}
                active={true}
                value={
                  submeterDetails == null
                    ? "Submeter is inactive"
                    : submeterDetails.datecreated
                    ? moment(submeterDetails.datecreated).format("lll")
                    : "Not issued to a company"
                }
                inactive={38}
              />
            </div>
          </div>
          <div className="row d-none">
            <div className="col-4">
              <div
                className="bg-white rounded-2"
                style={{
                  height: 180,
                }}
              >
                <div>Submeter Info</div>
                <div>sdlvsdsjn</div>
                <div className="w-50">
                  <Button text={"View details"} />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className=""></div>
            <div className="channels-title my-2">Channels on Submeter</div>
            <div className="bg-white rounded-2 shadow-sm">
              <div className="row d-none d-flex align-items-center justify-content-end p-2">
                <div className="col-3">
                  <input
                    type={"text"}
                    className="form-search w-100 rounded-2 p-2"
                    placeholder="search for channels"
                  />
                </div>
              </div>
              <div>
                <ChannelsTable
                  data={
                    submeterDetails == null
                      ? []
                      : submeterDetails.channelinfo.channeldata
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SubmeterInfo;
