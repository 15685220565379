import React, { useEffect, useState, Fragment } from "react";
import "./App.css";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import SignIn from "./screens/AuthScreens/Signin";
import Dashboard from "./screens/Dashboards";
import Company from "./screens/Company";
import CompanyDetails from "./screens/Company/CompanyDetails";
import toast, { Toaster } from "react-hot-toast";
import Metermodel from "./screens/MeterModel";
import Analytics from "./screens/Analytics";
import Messages from "./screens/Settings/Messages";
import Notification from "./screens/Settings/Notification";
import { getToken } from "./utils/token";
import Submeter from "./screens/Submeter";
import ModelInfo from "./screens/MeterModel/ModelInfo";
import SubmeterInfo from "./screens/Submeter/SubmeterInfo";
import NotFound from "./screens/NotFound";
import ShutDown from "./screens/Settings/Shutdown";
import DCU from "./screens/DCU";
import DCUInfo from "./screens/DCU/DCUInfo";
import DCUModel from "./screens/DCUModel";

function App() {
  return (
    <>
      <Toaster
        toastOptions={{
          duration: 5000,
        }}
      />
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="*" element={<NotFound />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/allcompanies" element={<Company />} />
          <Route path="/company/:id" element={<CompanyDetails />} />
          <Route path="/dcumodels" element={<DCUModel />} />
          <Route path="/metermodels" element={<Metermodel />} />
          <Route path="/metermodel/:id" element={<ModelInfo />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/settings/messages" element={<Messages />} />
          <Route path="/settings/server" element={<ShutDown />} />
          <Route path="/settings/notifications" element={<Notification />} />
          <Route path="/submeter" element={<Submeter />} />
          <Route path="/submeter/:id" element={<SubmeterInfo />} />
          <Route path="/dcu" element={<DCU />} />
          <Route path="/dcu/:id" element={<DCUInfo />} />
          {/* <Route path="/submeter/:id" element={<SubmeterInfo />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;

const ProtectedRoute = () => {
  const [userToken, setUserToken] = useState(false);
  const [loading, setloading] = useState(true);
  useEffect(() => {
    (async () => {
      try {
        let token = await getToken("spiral_token");
        if (token) {
          setloading(false);
          setUserToken(true);
          return;
        }
        setloading(false);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  if (loading) return <div>fetching resource....</div>;

  return userToken ? <Outlet /> : <Navigate to={"/signin"} />;
};
