import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";

const AddThread = ({ closeBtn, refreshBtn }) => {
  const [threadNum, setThreadNum] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleThread = async () => {
    try {
      if (3 > +threadNum < 1) {
        toast.error("Yon can only add max of 2 workers", {
          toastId: "3cev3rved",
        });
        return;
      }
      setLoading((prev) => true);
      let res = await axios.post("/notification/increaseworkerthreadcount", {
        additionalworkers: threadNum,
      });

      let result = companyController(res);
      if (result.type !== "success") {
        setLoading((prev) => false);
        toast.error("An error while adding thread. Please try again.");
        return;
      }

      setLoading((prev) => false);

      if (result.message.response == true) {
        toast.success("Success");
      } else {
        toast.error("Operation failed");
      }
      refreshBtn((prev) => !prev);
      closeBtn(false);
    } catch (err) {
      toast.error(err.message);
      setLoading((prev) => false);
    }
  };
  return (
    <div className="backdrop">
      <div className="thread-modal animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-4 modal-title pb-2">
              Increase thread count the server
            </div>
            <div>
              <div onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Thread count number
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="enter a number between 3 and 1..."
                  value={threadNum}
                  onChange={(e) => setThreadNum(e.target.value)}
                />
              </div>
              <div className="my-4" onClick={handleThread}>
                <Button
                  text={"Create"}
                  color={color.white}
                  bgColor={color.primary}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddThread;
