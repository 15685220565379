const ErrorText = ({ text }) => {
  return (
    <div
      className="m-0 text-danger fs-9 fw-light"
      style={{
        fontSize: 11,
      }}
    >
      {text ?? "Field is required."}
    </div>
  );
};

export default ErrorText;
