import React, { useState, useEffect } from "react";
import { getToken } from "../../utils/token";
import "./style.css";
import HomeBg from "../../assets/images/homebg.png";
const TopNav = () => {
  const [username, setUsername] = useState("");
  useEffect(() => {
    (async () => {
      try {
        let username = await getToken("spiral_user");
        setUsername(username);
      } catch (err) {
        console.log(err);
      }
    })();
    return () => {};
  }, []);

  return (
    <div className="nav px-3 bg-white d-flex align-items-center justify-content-between w-100 ">
      <div className="upply-name pt-2 d-flex align-items-center">
        <h6>Hello {username}</h6>
      </div>
      <div className="nav-right d-flex align-items-center">
        <div className="upgrade-cover p-2 rounded-3 d-flex align-items-center">
          <div className="upgrade-icon d-flex align-items-center justify-content-center mx-2">
            {/* <ion-icon name="star"></ion-icon> */}
          </div>
          {/* <div>Upgrade your plan</div> */}
        </div>
        <div className="noti-icon px-2 mx-3 d-flex align-items-center justify-content-center ">
          {/* <ion-icon name="notifications-outline" className="notify-icon"></ion-icon> */}
        </div>
        <div className="profile-icon  shadow-sm">
          <img
            src={
              "https://images.unsplash.com/photo-1665510383805-2394dc4d3323?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwyfHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=900&q=60"
            }
            className={"image-fluid"}
            width={"100%"}
            height={"100%"}
          />
        </div>
      </div>
    </div>
  );
};

export default TopNav;
