import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useEffect, useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";
import { Select } from "antd";
import { DatePicker } from "antd";
import { meterController } from "../../../Controller/meterController";
import { timeZone } from "./data";
import moment from "moment";
const UpdateSaas = ({ closeBtn, refreshBtn, meter }) => {
  const { Option } = Select;
  const [loading, setloading] = useState(false);
  console.log(meter, "//meter");
  const [date, setdate] = useState("");
  const handleSaas = async () => {
    try {
      if (!date) return toast.error("Please enter the correct value");
      setloading((prev) => true);
      let res = await axios.post(
        "/devicemanagement/submeter/setsaasexpiration",
        {
          meterid: "",
          companyalias: "",
          saasexpiration: moment(date).format("YYYY-MM-DD"),
        }
      );

      let result = meterController(res);
      if (result.message !== "success") {
        toast.error(result.message, {
          toastId: "23523tfecsamjnks",
        });
        setloading((prev) => false);
        return;
      }
      const { response } = result.data;
      // refreshBtn((prev) => !prev);
      // closeBtn(false);
      return;
    } catch (err) {
      toast.error(err.message);
      setloading((prev) => false);
    }
  };

  return (
    <div className="backdrop">
      <div className="submeter-add_modal animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-4 modal-title pb-2 text-start">
              Please select a date
            </div>
            <div>
              <div className="" onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <div className="company-entry">
                <label
                  className="form-label"
                  style={{
                    fontSize: 15,
                  }}
                >
                  Select new date
                </label>
                <div className="w-100">
                  <DatePicker
                    size="large"
                    value={date}
                    onChange={(e) => setdate(e)}
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </div>

              <div className="my-4" onClick={handleSaas}>
                <Button
                  text={"Create"}
                  color={color.white}
                  bgColor={color.primary}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSaas;
