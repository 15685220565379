export const meterController = (res) => {
  if (res.message === "Network Error") {
    return (window.location.pathname = "network");
  } else if (res.data) {
    return {
      data: res.data,
      message: "success",
    };
  } else if (res.data.response) {
    return {
      data: res.data.response,
      message: "failed",
    };
  } else if (res.status >= 500) {
    return (window.location.pathname = "/servererror");
  }
};

// remmber to handle the invalid session
