import * as React from "react";
import moment from "moment";
import { color } from "../../../constant/color";
import { Link } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import "./style.css";
import { tableCustomStyles } from "../../../HelperFunctions";

const CompnaiesTable = ({ data, loading }) => {
  const formatSecretKey = (item) =>
    `${item.substring(0, 5)}....${item.substring(
      item.length - 5,
      item.length
    )}`;

  const columns = [
    {
      center: true,
      name: "#",
      selector: (row, index) => index + 1,
      width: "50px",
    },
    {
      center: true,
      name: "Company name",
      selector: (row) => row.companyname,
      sortable: true,
    },
    {
      center: true,
      name: "Company email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      center: true,
      name: "Company phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      center: true,
      name: "Date created",
      selector: (row) => moment(row.datecreated).format("lll"),
      sortable: true,
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (
        <div
          className="status-btn p-1 rounded"
          style={{
            background:
              row.activestatus === "inactive" ? "#ffa58733" : "#6fed6f33",
            color: row.activestatus === "inactive" ? color.red : color.green,
          }}
        >
          {row.activestatus}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Link
          to={`/company/${row.companyalias}`}
          className="text-decoration-underline"
        >
          View
        </Link>
      ),
      button: true,
    },
  ];

  return (
    <div className="table-responsive mt-2">
      <DataTable
        customStyles={tableCustomStyles}
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
      />
    </div>
  );
};

export default CompnaiesTable;
