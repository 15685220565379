import { useState } from "react";
import CloseIcon from "../../CloseIcon";
import AddSingle from "../AddSingle";
import { data } from "./data";
import "./style.css";
import { Select } from "antd";
import { GiMicrochip } from "react-icons/gi";
import { color } from "../../../constant/color";
import AddMultiple from "../AddMultiple";
import AddSingleAndIssue from "../AddSingleAndIssue";
import AddMultipleAndIsssue from "../AddMultipleAndIsssue";
const AddSubmeter = ({ closeModal, refreshBtn }) => {
  const [type, setType] = useState(false);
  const [removemodal, setRemoveModal] = useState(false);
  const Card = ({ item }) => {
    return (
      <div
        className="addsubmeter-cover border p-3  rounded-3 bg-white flex-column d-flex align-items-start justify-content-start"
        onClick={() => {
          if (item.id == 1) {
            setType("single");
          } else if (item.id == 3) {
            setType("multiple");
          } else if (item.id == 2) {
            setType("singleissue");
          } else if (item.id == 4) {
            setType("multipleissue");
          }
          setRemoveModal(true);
        }}
      >
        {/* <div
          className="addsubmeter-icon rounded-2  d-flex align-items-center justify-content-center"
          style={{}}
        >
          <div className="">
            <GiMicrochip size={35} color={color.primary} />
          </div>
        </div> */}
        <div className="mt-2">
          <div className="addsubmeter-title">{item.title}</div>
          <div className="addsubmeter-desc">{item.desc}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="backdrop">
      {type == "single" && (
        <AddSingle closeBtn={closeModal} refreshBtn={refreshBtn} />
      )}
      {type == "multiple" && (
        <AddMultiple closeBtn={closeModal} refreshBtn={refreshBtn} />
      )}
      {type == "singleissue" && (
        <AddSingleAndIssue closeBtn={closeModal} refreshBtn={refreshBtn} />
      )}
      {type == "multipleissue" && (
        <AddMultipleAndIsssue closeBtn={closeModal} refreshBtn={refreshBtn} />
      )}

      <div
        className="submeter-modal p-3 animate__animated animate__fadeInUp bg-white shadow rounded"
        style={{
          display: removemodal ? "none" : "block",
        }}
      >
        <div className="text-left fw-bold mb-2">
          Select from any the options below to create/issue a submeter
        </div>
        <div
          style={{
            position: "absolute",
            top: "8px",
            right: 20,
          }}
          onClick={() => closeModal(false)}
        >
          <CloseIcon />
        </div>

        <div className="container">
          <div className="row">
            {data.map((i) => (
              <div
                className="col-sm-12 col-md-5 col-lg-5 col-xl-6 col-xxl-6 my-2"
                key={i.id}
              >
                <Card item={i} />
              </div>
            ))}
          </div>
          <div className="row"></div>
        </div>
      </div>
    </div>
  );
};

export default AddSubmeter;
