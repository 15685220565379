import axios from "../../../utils/axios";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Layout from "../../../components/Layout";
import QueuesLineChart from "../../../components/Messages/QueuesLineChart";
import TopNav from "../../../components/TopNav";
import { color } from "../../../constant/color";
import { companyController } from "../../../Controller/CompanyController";
import Button from "../../../components/Button";
import Messagestable from "../../../components/Messages/Messagestable";
import MessagesFiles from "../../../components/Messages/MessagesFiles";
import AddThread from "../../../components/Messages/AddThread";

const Messages = () => {
  const [tabIndex, setTabIndex] = useState(1);
  const [msgqueuesData, setMsgQueueData] = useState([]);
  const [msgqworkerData, setMsgWorkerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [refreshMsgQh, setRefreshMsgQh] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/message/getqueuecapacityhistory", {});

        let result = companyController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          return;
        }
        setMsgQueueData(result.message.body);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [refreshMsgQh]);

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/message/getcurrentstatusofworkers", {});
        let result = companyController(res);
        if (result.type !== "success") {
          toast.error(result.message);
          return;
        }

        setMsgWorkerData(result.message);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, [refresh]);
  return (
    <Layout>
      <TopNav />
      <div className="px-3">
        <div
          className="container-fluid mt-5 bg-white shadow-sm rounded "
          style={{
            marginBottom: 200,
          }}
        >
          {modal && <AddThread closeBtn={setModal} refreshBtn={setRefresh} />}
          <div
            className="w-100 d-flex align-items-center justify-content-start"
            style={{
              borderBottom: "1px solid #eee",
            }}
          >
            <div
              className="px-3 px-4 py-2 mx-2"
              style={{
                borderBottom:
                  tabIndex == 1 ? `2px solid ${color.primary}` : color.black,
                color: tabIndex == 1 ? color.primary : color.black,
                cursor: "pointer",
              }}
              onClick={() => setTabIndex(1)}
            >
              Queue
            </div>
            <div
              className="px-3 px-4 py-2 mx-2"
              style={{
                borderBottom:
                  tabIndex == 2 ? `2px solid ${color.primary}` : color.black,
                color: tabIndex == 2 ? color.primary : color.black,
                cursor: "pointer",
              }}
              onClick={() => setTabIndex(2)}
            >
              Workers
            </div>
            <div
              className="px-3 px-4 py-2 mx-2"
              style={{
                borderBottom:
                  tabIndex == 3 ? `2px solid ${color.primary}` : color.black,
                color: tabIndex == 3 ? color.primary : color.black,
                cursor: "pointer",
              }}
              onClick={() => setTabIndex(3)}
            >
              Files
            </div>
          </div>
          <div
            className="p-2"
            style={{
              height: "auto",
            }}
          >
            {tabIndex == 1 && (
              <div className="mt-3 row justify-content-end">
                <div
                  className="col-sm-10 col-md-5 col-lg-2 col-xl-2 col-xxl-2"
                  onClick={() => setRefreshMsgQh((prev) => !prev)}
                >
                  <Button
                    text={"Refresh"}
                    bgColor={color.primary}
                    fontSize={"13px"}
                  />
                </div>
                <QueuesLineChart data={msgqueuesData} />
              </div>
            )}

            {tabIndex == 2 && (
              <div className="my-3 row justify-content-end">
                <div
                  className="col-sm-10 col-md-5 col-lg-2 col-xl-2 col-xxl-2"
                  onClick={() => setModal(true)}
                >
                  <Button
                    text={"Increase thread count"}
                    bgColor={color.primary}
                    fontSize={"13px"}
                  />
                </div>
                <Messagestable data={msgqworkerData} />
              </div>
            )}

            {tabIndex == 3 && (
              <div className="mt-3 row justify-content-end">
                <MessagesFiles />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Messages;
