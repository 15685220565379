import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Layout from "../../components/Layout";
import SubmeterTable from "../../components/Submeter/Table/SubmeterTable";
import TopNav from "../../components/TopNav";
import { meterController } from "../../Controller/meterController";
import ReactPaginate from "react-paginate";
import { Pagination } from "antd";
import { paginationGood } from "../../HelperFunctions";
import Button from "../../components/Button";
import { color } from "../../constant/color";
import AddSubmeter from "../../components/Submeter/AddSubmeter";
import AddDCU from "../../components/DCU/modals/AddDCU";
import { useGetDCUMutation } from "../../services/device.services";
import DCUTable from "../../components/DCU/table/DCUTable";
import { CustomButton } from "../../components/CustomButton";
import IssueDCUToCompany from "../../components/DCU/modals/IssueDCUToCompany";

const DCU = () => {
  const [meter, setMeter] = useState([]);
  const [modal, setmodal] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [getDCU, { isLoading, error, status, data }] = useGetDCUMutation();
  const [inputname, setinputname] = useState("");

  useEffect(() => {
    getDCU();
  }, [refresh]);

  const filteredDCU = () => {
    return data?.body.filter(
      (i) =>
        i.dcuid.toLowerCase().trim().includes(inputname.trim().toLowerCase()) ||
        i.devicemodel
          .toLowerCase()
          .trim()
          .includes(inputname.trim().toLowerCase())
    );
  };

  return (
    <Layout>
      <TopNav />

      {modal && <AddDCU closeModal={setmodal} refreshBtn={setrefresh} />}
      <div className="mt-3 mx-3 p-2 bg-white shadow rounded-2">
        <div className="row mb-3 d-flex align-items-center justify-content-between">
          <div className="col-3">
            <h3 className="m-0 h5" style={{ fontSize: 17 }}>
              Device
            </h3>
            <p className="m-0" style={{ fontSize: 15 }}>
              List of DCUs you have created
            </p>
          </div>
          <div className="col-6">
            <div className="row d-flex justify-content-end align-items-center">
              <div className="col-4 d-flex justify-content-end align-items-center">
                <input
                  className="form-input px-2"
                  style={{ outline: "none" }}
                  value={inputname}
                  onChange={(e) => setinputname(e.target.value)}
                  placeholder="Search for dcu"
                />
              </div>
              <div className="col-3" onClick={() => setmodal(true)}>
                <Button
                  text={"Add DCU"}
                  bgColor={color.primary}
                  fontSize={"12px"}
                  height={"40px"}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-0" />
        <div className="p-2">
          <DCUTable loading={isLoading} data={filteredDCU()} />
        </div>
      </div>
    </Layout>
  );
};

export default DCU;
