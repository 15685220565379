import moment from "moment";
import { Link } from "react-router-dom";
import { color } from "../../../constant/color";
import { IoCalendarOutline } from "react-icons/io5";
import DataTable, { TableColumn } from "react-data-table-component";
import React, { useState } from "react";
import { tableCustomStyles } from "../../../HelperFunctions";
import { FaEye } from "react-icons/fa";
import { CustomButton } from "../../CustomButton";
import IssueDCUToCompany from "../modals/IssueDCUToCompany";

const DCUTable = ({ data, loading }) => {
  const [issuemodal, setissuemodal] = useState(false);
  const [issuemeter, setissuemeter] = useState(false);

  const columns = [
    {
      name: "DCU Id",
      selector: (row) => row.dcuid,
      sortable: true,
      grow: 10,
      center: true,
    },
    {
      name: "Device Model",
      selector: (row) => row.devicemodel,
      sortable: true,
      center: true,
      grow: 8,
    },
    {
      name: "Company Alias",
      cell: (row) => (
        <div className="rounded p-2">
          {row.companyalias == null ? "-Not assigned-" : row.companyalias}
        </div>
      ),
      center: true,
      grow: 8,
    },
    {
      name: "Serial id",
      selector: (row) => row.serialid,
      sortable: true,
      center: true,
      grow: 6,
    },
    {
      name: "Saasexpirydate",
      cell: (row) => (
        <div
          style={{
            fontSize: 12,
          }}
          className="mx-2"
        >
          {row.saasexpirydate == null
            ? "-Nil-"
            : moment(row.saasexpirydate).format("YYYY-MM-DD")}
        </div>
      ),
      sortable: true,
      center: true,
      grow: 8,
    },
    {
      name: "Datecreated",
      cell: (row) => (
        <div
          style={{
            fontSize: 12,
          }}
          className="mx-2"
        >
          {row.datecreated == null
            ? "-Nil-"
            : moment(row.datecreated).format("YYYY-MM-DD")}
        </div>
      ),
      sortable: true,
      center: true,
      grow: 8,
    },
    {
      name: "Dateassigned",
      cell: (row) => (
        <div
          style={{
            fontSize: 12,
          }}
          className="mx-2"
        >
          {row.dateassigned == null
            ? "-Nil-"
            : moment(row.dateassigned).format("YYYY-MM-DD")}
        </div>
      ),
      sortable: true,
      center: true,
      grow: 8,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex aligns-items-center justify-content-around">
          <Link
            to={`/dcu/${row.dcuid}`}
            className="text-decoration-underline text-decoration-none  p-2 rounded-1 border border-primary"
          >
            {/* <FaEye size={15} /> */}
            View more
          </Link>
        </div>
      ),
      button: true,
    },
  ];

  const [selectRows, setselectedRows] = useState([]);
  const handleChange = ({ selectedRows }) => {
    setselectedRows(selectedRows);
  };

  return (
    <div className="w-100">
      {issuemodal && (
        <IssueDCUToCompany closeBtn={setissuemodal} refreshBtn={null} />
      )}
      {selectRows.length > 0 && (
        <div className="d-none mb-2 d-flex align-items-center justify-content-end">
          <div className="col-2">
            <div className="">
              <CustomButton
                className={
                  "w-100 text-black border-none outline-none border-0 p-2 rounded"
                }
                onClick={() => setissuemodal(true)}
                styles={{
                  background: "#eee",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontSize: 14,
                  }}
                >
                  Assign DCU to company
                </p>
              </CustomButton>
            </div>
          </div>
          <div className="col-2 d-none">
            <div className="">
              <CustomButton
                className={
                  "w-100 text-black border-none outline-none border-0 p-2 rounded"
                }
                styles={{
                  background: "#eee",
                }}
              >
                <p
                  className="m-0"
                  style={{
                    fontSize: 14,
                  }}
                >
                  Add meter to DCU
                </p>
              </CustomButton>
            </div>
          </div>
        </div>
      )}
      <DataTable
        customStyles={tableCustomStyles}
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        selectableRows={true}
        onSelectedRowsChange={handleChange}
      />
    </div>
  );
};

export default DCUTable;
