import { color } from "../../../../constant/color";
import Button from "../../../Button";
import CloseIcon from "../../../CloseIcon";
import React, { useEffect, useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../../utils/axios";
import { Input, Select } from "antd";
import { meterController } from "../../../../Controller/meterController";
import { useForm } from "react-hook-form";
import {
  useCreateDCUAutoMutation,
  useCreateDCUMutation,
} from "../../../../services/device.services";
import { CustomButton } from "../../../CustomButton";
import ErrorText from "../../../Errors/ErrorText";
import { errorBlock } from "../../../../Controller/errorBlock";
import { deviceController } from "../../../../Controller/deviceController";
import {
  useCreateDCUModelMutation,
  useGetDCUModelsMutation,
} from "../../../../services/dcumodel.services";
import { Radio } from "antd";
const AddDCUModel = ({ closeBtn, refreshBtn }) => {
  const { Option } = Select;
  const [serialid, setSerialid] = useState("");
  const [meterid, setMeterid] = useState("");
  const [metermodel, setMeterModel] = useState([]);
  const [selectedmodel, setselectedmodel] = useState("");
  const [loading, setLoading] = useState(false);
  const [createDCUModel, { isLoading, error, status }] =
    useCreateDCUModelMutation();
  const [
    getDCUModels,
    {
      isLoading: loadingModels,
      error: modelError,
      status: modelStatus,
      data: modelData,
    },
  ] = useGetDCUModelsMutation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleSubmeter = async (data) => {
    try {
      let res = await createDCUModel(data).unwrap();
      const result = deviceController(res);

      if (result.type != "success") {
        toast.error("Failed to create DCU.");
        return;
      }

      console.log(result, "");
      refreshBtn((prev) => !prev);
      closeBtn(false);
      toast.success("DCU model created successfully.");
    } catch (err) {
      console.log(err, "error");
      errorBlock(err);
    }
  };

  // dcumodel, ;

  return (
    <div className="backdrop">
      <div className="submeter-add_modal animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-2 pb-2 ">
              Fill the input fields to a create a DCU model
            </div>
            <div>
              <div className="" onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row pt-3">
            <form onSubmit={handleSubmit(handleSubmeter)} className="col-12">
              <div class="mb-3 company-entry">
                <label>Enter model name</label>
                <input
                  size="large"
                  type="text"
                  class="form-control mt-1"
                  {...register("dcumodel", {
                    required: true,
                  })}
                  // onInput={(e) => {
                  //   if (e.target.value.length > e.target.maxLength) {
                  //     e.target.value = e.target.value.slice(
                  //       0,
                  //       e.target.maxLength
                  //     );
                  //   }
                  // }}
                  maxLength="10"
                  placeholder="Enter DCU model e.g (NFF-XG or MSK-RF)"
                />
                {errors?.dcumodel && <ErrorText />}
              </div>

              <div class="mb-3 company-entry">
                <label>Enter model capacity</label>
                <input
                  size="large"
                  class="form-control mt-1"
                  placeholder="Enter DCU capacity"
                  {...register("devicecapacity", {
                    required: true,
                  })}
                  type={"number"}
                />
                {errors.dcuid && <ErrorText />}
              </div>

              <div className="my-4">
                <CustomButton
                  loading={isLoading}
                  className={
                    "w-100 border-none outline-none border-0 bg-primary text-white p-2 rounded"
                  }
                  styles={{
                    background: color.primary,
                  }}
                >
                  <p
                    className="m-0"
                    style={{
                      fontSize: 15,
                    }}
                  >
                    Create DCU
                  </p>
                </CustomButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDCUModel;
