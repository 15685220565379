import React from "react";
import "./style.css";
import { IoPersonCircleSharp, IoHardwareChipSharp } from "react-icons/io5";
import { IoIosBookmarks } from "react-icons/io";
import { HiColorSwatch, HiScale } from "react-icons/hi";
import { RiBuildingFill, RiServerFill } from "react-icons/ri";

const TopCards = ({
  title,
  value,
  iconType,
  bgColor,
  showCompanyInfo,
  active,
  inactive,
  fontSize,
}) => {
  return (
    <div className="top-card bg-white border shadow-sm rounded-3 p-2 d-flex align-items-center flex-column justify-content-center">
      <div className="card-bottom w-100 d-flex align-items-center justify-content-between mt-3">
        <div
          className="card-img d-flex align-items-center justify-content-center"
          style={{
            backgroundColor:
              iconType == "company"
                ? "#99c5ff65"
                : iconType == "submeter"
                ? "#6fed6f33"
                : iconType == "model"
                ? "#a86bfd3b"
                : "#ffb2a44c",
          }}
        >
          {iconType == "company" ? (
            <RiBuildingFill size={25} color={"dodgerblue"} />
          ) : iconType == "submeter" ? (
            <IoHardwareChipSharp size={25} color={"#3a9d3a"} />
          ) : iconType == "model" ? (
            <RiServerFill size={20} color={"#752bdf"} />
          ) : (
            <HiColorSwatch size={20} color={"tomato"} />
          )}
        </div>
        <div className=" w-75 mx-3 ">
          <div
            className="text-left"
            style={{
              fontSize: fontSize || "30px",
              fontWeight: "bold",
              color: "#2b3648",
            }}
          >
            {value}
          </div>
          <div className="title">{title}</div>
        </div>
      </div>
      {showCompanyInfo ? (
        <div className="d-flex align-items-center justify-content-between w-100 mt-2">
          <div className="top-card_info rounded-2 px-2">{active} active</div>
          <div className="top-card_info rounded-2 px-2">
            {inactive} suspended{" "}
          </div>
        </div>
      ) : (
        <div
          style={{
            height: 20,
          }}
        ></div>
      )}
    </div>
  );
};

export default TopCards;
