import moment from "moment";
import { Link } from "react-router-dom";
import { color } from "../../../constant/color";
import ReactPaginate from "react-paginate";
import React, { useState } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import Button from "../../Button";
import "./style.css";
import { flattenArr } from "../../../HelperFunctions";
import CloseIcon from "../../CloseIcon";

const ChannelsTable = ({ data }) => {
  return (
    <div
      style={{
        overflow: "scroll",
        height: 500,
      }}
    >
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Channel Id</th>
            <th scope="col">Power status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((i, index) => (
            <tr key={index}>
              <td scope="row">{index + 1}</td>
              <td>{i.meterid}</td>
              <td
                style={{
                  color: i.powerstatus == "success" ? color.green : color.red,
                }}
              >
                {i.powerstatus}
              </td>
              <td>
                <Link to={`/submeter/`} className="text-decoration-underline">
                  View info
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChannelsTable;
