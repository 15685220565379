import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useEffect, useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";
import { Select } from "antd";
import { DatePicker } from "antd";
import { meterController } from "../../../Controller/meterController";
import { timeZone } from "./data";
import moment from "moment";
const IssueSubmeter = ({ closeBtn, refreshBtn, meterid }) => {
  const { Option } = Select;
  const [selectedmodel, setselectedmodel] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setselectedCompany] = useState("");
  const [company, setcompany] = useState([]);
  const [date, setdate] = useState("");
  const [timezone, settimezone] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post("/management/getallcompanydata", {});
        let result = companyController(res);

        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setcompany(result.message.body);
      } catch (err) {
        toast.err(err.message);
      }
    })();
  }, []);

  const handleSubmeter = async () => {
    try {
      if (!selectedCompany || !date || !timezone)
        return toast.error("All values are required");
      setLoading((prev) => true);
      let res = await axios.post(
        "/devicemanagement/submeter/issuesubmetertocompanysingle",
        {
          meterid,
          companyalias: selectedCompany,
          saasexpiration: date,
          timezone,
        }
      );
      let result = companyController(res);
      if (result.type !== "success") {
        toast[result.type](result.message);
        setLoading((prev) => false);
        return;
      }
      const { response } = result.message;
      if (response == "success") {
        toast.success("Submeter issued to company successfully.");
      } else {
        toast.error(
          "An error occured while issuing submeter to company. Please try again."
        );
      }
      setLoading((prev) => false);
      refreshBtn((prev) => !prev);
      closeBtn(false);
    } catch (err) {
      toast.error(err.message);
      setLoading((prev) => false);
    }
  };
  return (
    <div className="backdrop">
      <div className="submeter-add_modal animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-4 modal-title pb-2 text-start">
              Fill the input fields to Issue submeter to a company
            </div>
            <div>
              <div className="" onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Select a company
                </label>
                <div className="w-100">
                  <Select
                    className="form-input w-100"
                    placeholder={"select a company"}
                    onChange={(e) => setselectedCompany(e)}
                    size="large"
                  >
                    {company.map((i, index) => (
                      <Option key={index} value={i.companyalias}>
                        {i.companyname}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div class="mb-3 company-entry">
                <label for="exampleInputEmail1" class="form-label">
                  Select timezone
                </label>

                <div className="w-100">
                  <Select
                    className="form-input w-100"
                    placeholder={"select a meter model"}
                    onChange={(e) => settimezone(e)}
                    size="large"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {timeZone.map((i) => (
                      <Option key={i.id} value={i.value}>
                        {i.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div class="mb-3 company-entry w-100">
                <label for="exampleInputEmail1" class="form-label">
                  Select a meter model
                </label>
                <div className="w-100">
                  <DatePicker
                    showTime
                    size="large"
                    style={{
                      width: "100%",
                      height: 40,
                    }}
                    onChange={(e) =>
                      setdate(moment(e._d).format("YYYY-MM-DD HH:mm:ss"))
                    }
                  />
                </div>
              </div>
              <div className="my-4" onClick={() => handleSubmeter()}>
                <Button
                  text={"Create"}
                  color={color.white}
                  bgColor={color.primary}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueSubmeter;
