import React from "react";
import "./style.css";
import { IoPersonCircleSharp, IoHardwareChipSharp } from "react-icons/io5";
import { IoIosBookmarks } from "react-icons/io";
import { HiScale } from "react-icons/hi";
import { RiBuildingFill, RiServerFill } from "react-icons/ri";

const TopCards = ({ title, value, iconType, bgColor, showCompanyInfo }) => {
  return (
    <div className="top-card bg-white border shadow-sm rounded-3 p-2 d-flex align-items-center flex-column justify-content-center">
      <div className="card-bottom mb-3 w-100 d-flex align-items-center justify-content-between mt-3">
        <div
          className="card-img d-flex align-items-center justify-content-center"
          style={{
            backgroundColor:
              iconType == "company"
                ? "#99c5ff65"
                : iconType == "activecompany"
                ? "#6fed6f33"
                : iconType == "inactivecompany"
                ? "#a86bfd3b"
                : "#ffb2a44c",
          }}
        >
          {iconType == "company" ? (
            <RiBuildingFill size={25} color={"dodgerblue"} />
          ) : iconType == "activecompany" ? (
            <RiBuildingFill size={25} color={"#3a9d3a"} />
          ) : iconType == "inactivecompany" ? (
            <RiBuildingFill size={25} color={"#752bdf"} />
          ) : (
            <HiScale size={20} color={"tomato"} />
          )}
        </div>
        <div className=" w-75 mx-3 ">
          <div className="card-caption text-left">{value}</div>
          <div className="title">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default TopCards;
