export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const paginationGood = (result, page_size, page_number) => {
  return result.slice(
    page_number * page_size,
    page_number * page_size + page_size
  );
};

export const flattenArr = (arr) => {
  let base = {};
  for (let i of arr) {
    for (let k in i) {
      base[k] = i[k];
    }
  }
  return Object.entries(base).map((item) => ({
    submeterId: item[0],
    status: item[1],
  }));
};

export const tableCustomStyles = {
  headRow: {
    style: {
      color: "black",
      backgroundColor: "#ecf4f9",
    },
  },
  rows: {
    style: {
      color: "STRIPEDCOLOR",
      backgroundColor: "STRIPEDCOLOR",
    },
    stripedStyle: {
      color: "NORMALCOLOR",
      backgroundColor: "NORMALCOLOR",
    },
  },
};
