import { useState } from "react";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import TopNav from "../../../components/TopNav";
import { color } from "../../../constant/color";
import "./styles.css";
import ServerImg from "../../../assets/svg/serverimg.svg";
import toast from "react-hot-toast";
import ServerModal from "../../../components/Settings/ServerModal";

const ShutDown = () => {
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);
  const [modal, setmodal] = useState(false);

  return (
    <Layout>
      <TopNav />
      {modal && <ServerModal closeBtn={setmodal} />}
      <div className="px-3 mt-5">
        <div
          className="container d-flex align-items-center justify-content-center"
          style={{
            height: "100vh",
            marginTop: -100,
          }}
        >
          <div className="col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4">
            <div className="bg-white shadow-sm server-card rounded-2 p-2 d-flex flex-column justify-content-between">
              <div>
                <div className="w-100 my-3 d-flex align-items-center justify-content-center">
                  <div className="server-img ">
                    <img
                      src={ServerImg}
                      alt="server image"
                      className="image-fluid"
                    />
                  </div>
                </div>

                <div className="text-center server-title">
                  Server Shutdown Process
                </div>
                <div className="server-caption py-1 text-center">
                  You about to shutdown the headend server for Spiral energy. By
                  doing this all Submeters and Companies activities will be
                  suspended. Click on I understand to continue.
                </div>
                <div
                  class="mb-3 mt-3 form-check d-flex"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    onClick={() => setshow((prev) => !prev)}
                  >
                    <div
                      className="rounded-2 border "
                      style={{
                        width: 17,
                        height: 17,
                        background: show ? color.primary : color.white,
                      }}
                    ></div>
                    <div
                      class="form-check-label px-1 text-docoration-underline"
                      htmlFor="exampleCheck1"
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      I understand
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="my-2"
                onClick={() => {
                  if (!show) {
                    toast.error(
                      "Please click on the 'I understand' to continue'"
                    );
                    return;
                  } else {
                    setmodal(true);
                  }
                }}
              >
                <Button
                  text={"Shut down"}
                  loading={loading}
                  color={color.white}
                  bgColor={color.primary}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ShutDown;
