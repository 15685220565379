import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LineChart from "../../components/Dashboards/Charts/LineChart";
import CompnaiesTable from "../../components/Dashboards/Tables/Companies";
import TopCards from "../../components/Dashboards/TopCards";
import Layout from "../../components/Layout";
import TopNav from "../../components/TopNav";
import { getToken } from "../../utils/token";
import axios from "../../utils/axios";

import "./style.css";
import moment from "moment";
import { energyController } from "../../Controller/energyController";
import toast from "react-hot-toast";
import { meterController } from "../../Controller/meterController";
import { companyController } from "../../Controller/CompanyController";
import { DatePicker } from "antd";
const controller = new AbortController();
const Dashboard = () => {
  let location = useLocation();
  const [startdate, setstartdate] = useState(
    moment(Date.now()).subtract(30, "days").format("YYYY-MM-DD HH:mm:ss")
  );
  const controller = new AbortController();
  const [enddate, setenddate] = useState(
    moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
  );

  let { RangePicker } = DatePicker;
  const [companyData, setCompanyData] = useState([]);
  const [companycount, setcompanycount] = useState(0);
  const [companycountactive, setcompanycountactive] = useState(0);
  const [companycountinactive, setcompanycountinactive] = useState(0);
  const [modelcount, setmodelcount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pqData, setpqData] = useState([]);
  const [servertime, setservertime] = useState("");
  const [metercount, setmetercount] = useState(0);
  useEffect(() => {
    (async () => {
      try {
        let fetch = await axios.post("/management/getallcompanydata", {});
        let result = energyController(fetch);
        if (result.message !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }

        setCompanyData(result.data.body);
        setcompanycount(result.data.meta.count);
        setLoading(false);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    getTime();

    let time = setInterval(() => {
      getTime();
    }, 60000);
    return () => {
      controller.abort();
      clearInterval(time);
    };
  }, []);

  async function getTime() {
    try {
      let fetch = await axios.get("/server/firmware/serverdatetime", {});
      // setservertime(moment(fetch.data)).format("lll");
      setservertime(moment(String(fetch.data)).format("llll"));
    } catch (err) {
      toast.error(err.message);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        let activeCompany = await axios.post(
          "/management/getallactivecompanydata",
          {}
        );
        let result = energyController(activeCompany);
        if (result.message !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }

        setcompanycountactive(result.data.meta.count);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let inactiveCompany = await axios.post(
          "/management/getallinactivecompanydata",
          {}
        );
        let result = energyController(inactiveCompany);
        if (result.message !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }

        setcompanycountinactive(result.data.meta.count);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let metermodel = await axios.post(
          "/devicemanagement/submeter/getallmetermodels",
          {}
        );
        setmodelcount(metermodel.data.meta.datapointcount);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  const [refresh, setrefresh] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let response = await axios.post(
          "/devicemanagement/submeter/getallmeteranalytics",
          {
            meterid: JSON.stringify([]),
            devicemodel: JSON.stringify([]),
            serialid: JSON.stringify([]),
            companyalias: JSON.stringify([]),
            timezone: JSON.stringify([]),
            activestatus: "",
            startdatecreated: moment(Date.now())
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss"),
            enddatecreated: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
            startdateassigned: moment(Date.now())
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss"),
            enddateassigned: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
            startsaasexpirationdate: moment(Date.now())
              .startOf("year")
              .format("YYYY-MM-DD HH:mm:ss"),
            endsaasexpirationdate: moment(Date.now()).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            powerstatus: "",
          }
        );
        setmetercount(response.data.meta.count);
      } catch (err) {
        toast.error(err.message);
      }
    })();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Layout>
      <>
        <TopNav />
        <div className="container-fluid px-3 mt-3">
          {/* Cards */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <TopCards
                  showCompanyInfo={true}
                  bgColor={"company"}
                  iconType={"company"}
                  title={"Companies"}
                  active={companycountactive}
                  value={companycount}
                  inactive={companycountinactive}
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <TopCards
                  showCompanyInfo={false}
                  bgColor={"submeter"}
                  iconType={"submeter"}
                  value={metercount}
                  title={"Submeters"}
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <TopCards
                  showCompanyInfo={false}
                  bgColor={"model"}
                  iconType={"model"}
                  value={modelcount}
                  title={"Meter Model"}
                />
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <TopCards
                  showCompanyInfo={false}
                  bgColor={""}
                  iconType={""}
                  value={servertime}
                  title={"Server time"}
                  fontSize={"20px"}
                />
              </div>
            </div>

            {/* Charts */}
            <div className="row mt-4 shadow bg-white">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-3 mt-3">
                <div className="w-100 bg-white shadoe">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="h6">Recently created company.</div>
                    <Link
                      to={"/allcompanies"}
                      className=" text-decoration-underline"
                      style={{
                        fontSize: 13,
                      }}
                    >
                      View all
                    </Link>
                  </div>

                  <CompnaiesTable loading={loading} data={companyData} />
                </div>
              </div>
            </div>
            {/* Charts */}
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Dashboard;
