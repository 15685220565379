import React, { useState } from "react";
import "./style.css";
import FilesImg from "../../assets/svg/transfer.svg";
import Button from "../Button";
import { color } from "../../constant/color";
import axios from "../../utils/axios";
import toast from "react-hot-toast";
import { companyController } from "../../Controller/CompanyController";

const MessagesFiles = () => {
  const [loading, setLoading] = useState({
    readLoading: false,
    persistLoading: false,
  });

  const handlePersist = async () => {
    try {
      setLoading({ persistLoading: true });
      let res = await axios.post("/message/persistqueuetofilesystem", {});
      let result = companyController(res);
      if (result.type !== "success") {
        setLoading({ persistLoading: false });
        toast.error("An error occured while fetching power quality data.");
        return;
      }
      setLoading({ persistLoading: false });
      if (result.message.response == true) {
        toast.success("Success");
      } else {
        toast.error("Operation failed");
      }
    } catch (err) {
      setLoading({ persistLoading: false });
      toast.error(err.message);
    }
  };
  const handleRead = async () => {
    try {
      setLoading({ readLoading: true });
      let res = await axios.post("/message/readqueuefromfilesystem", {});
      let result = companyController(res);
      if (result.type !== "success") {
        setLoading({ readLoading: false });
        toast.error("An error occured while fetching power quality data.");
        return;
      }
      setLoading({ readLoading: false });
      if (result.message.response) {
        toast.success("Success");
      } else {
        toast.error("Operation failed");
      }
    } catch (err) {
      toast.error(err.message);
      setLoading({ readLoading: false });
    }
  };
  return (
    <div
      className="border rounded-3 container-fluid p-3 "
      style={{
        height: "auto",
      }}
    >
      <div className="row d-flex align-items-center justify-content-center file-cover">
        <div className="w-100 text-center page-title">File System</div>
        <div className="w-100 text-center page-caption pb-5" style={{}}>
          Select from the options below to process queues on the server.
        </div>
      </div>
      <div className="row mt-sm-3 d-flex align-items-center justify-content-center">
        <div className="col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4 my-2">
          <div className="file-card  shadow-sm p-3 bg-white rounded-3 border ">
            <div className="file-img">
              <img src={FilesImg} alt="file img" />
            </div>
            <div className="file-title">Persist file from file System</div>
            <div className="file-caption pb-3">
              This presrvers RAM notification when Database management system
              (DBMS) connectivity fails
            </div>
            <div className="" onClick={handlePersist}>
              <Button
                text={"Persist"}
                bgColor={color.primary}
                color={color.white}
                status={loading.persistLoading}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-4 my-2">
          <div className="file-card  shadow-sm p-3 bg-white rounded-3 border ">
            <div className="file-img">
              <img src={FilesImg} alt="file img" />
            </div>
            <div className="file-title">Read file from file System</div>
            <div className="file-caption pb-3">
              This reads the RAM notification when Database management system
              (DBMS) connectivity fails
            </div>
            <div className="" onClick={handleRead}>
              <Button
                text={"Read"}
                bgColor={color.primary}
                color={color.white}
                status={loading.readLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesFiles;
