import { color } from "../../../constant/color";
import Button from "../../Button";
import CloseIcon from "../../CloseIcon";
import React, { useEffect, useState } from "react";
import "./style.css";
import toast from "react-hot-toast";
import axios from "../../../utils/axios";
import { companyController } from "../../../Controller/CompanyController";
import { Select } from "antd";
import { DatePicker } from "antd";
import { meterController } from "../../../Controller/meterController";
import { timeZone } from "./data";
import moment from "moment";
const StatusModal = ({ closeBtn, refreshBtn, meterid, type }) => {
  const { Option } = Select;
  const [loading, setloading] = useState(false);
  const [timezone, settimezone] = useState("");
  const [password, setpassword] = useState("");
  const [text, settext] = useState("");
  const formatStatus = () => {
    if (type == "active") {
      return {
        word: "deactivate",
        color: color.red,
      };
    } else {
      return {
        word: "activate",
        color: color.green,
      };
    }
  };

  const handleStatus = async () => {
    if (type == "active") {
      handleDeactivate();
    } else {
      return await handleActivate();
    }
  };

  const handleActivate = async () => {
    try {
      console.log(text, "///////");
      if (text !== "activate")
        return toast.error("Please enter the correct value");
      setloading((prev) => true);
      let res = await axios.post(
        "/devicemanagement/submeter/activatesubmeter",
        {
          meterid,
        }
      );

      let result = meterController(res);
      if (result.message !== "success") {
        toast.error(result.message, {
          toastId: "23523tfecsamjnks",
        });
        setloading((prev) => false);
        return;
      }
      const { response } = result.data;
      if (response == "success") {
        toast.success("Activated submeter succssfully", {
          toastId: "veirovv92304ecnj",
        });
        refreshBtn((prev) => !prev);
        setloading((prev) => true);
        closeBtn(false);
        return;
      } else {
        toast.success("Operation failed.Please try again");
        refreshBtn((prev) => !prev);
        setloading((prev) => true);
        closeBtn(false);
      }
    } catch (err) {
      toast.error(err.message);
      setloading((prev) => false);
    }
  };

  const handleDeactivate = async () => {
    try {
      if (text !== "deactivate")
        return toast.error("Please enter the correct value");
      setloading((prev) => true);
      let res = await axios.post(
        "/devicemanagement/submeter/deactivatesubmeter",
        {
          meterid,
        }
      );
      let result = meterController(res);
      if (result.message !== "success") {
        toast.error(result.message, {
          toastId: "23523tfecsamjnks",
        });
        setloading((prev) => false);
        return;
      }
      const { response } = result.data;
      if (response == "success") {
        toast.success("Deactivated submeter succssfully", {
          toastId: "veirovv92304ecnj",
        });
        refreshBtn((prev) => !prev);
        setloading((prev) => false);
        closeBtn(false);
        return;
      }
    } catch (err) {
      toast.error(err.message);
      setloading((prev) => false);
    }
  };
  return (
    <div className="backdrop">
      <div className="submeter-add_modal animate__animated animate__fadeInUp bg-white rounded-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pt-4 modal-title pb-2 text-start">
              To perform this operation you need to put your password to
              continue
            </div>
            <div>
              <div className="" onClick={() => closeBtn(false)}>
                <CloseIcon />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <div className="company-entry">
                <label
                  className="form-label"
                  style={{
                    fontSize: 15,
                  }}
                >
                  Are you sure you want to{" "}
                  {type == "active" ? "deactivate" : "activate"} this submeter
                  ?. By doing so all activties of the meter will be{" "}
                  {type == "active" ? "suspended" : "activated"}. Copy and paste
                  <span
                    className="rounded-4 px-2 py-1 mx-2"
                    style={{
                      background: "#eee",
                      color: type == "active" ? color.red : color.green,
                    }}
                  >
                    {type == "active" ? "deactivate" : "activate"}
                  </span>{" "}
                  in the field below to continue.
                </label>
                <div className="w-100">
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder=""
                    value={text}
                    onChange={(e) => settext(e.target.value)}
                  />
                </div>
              </div>

              <div className="my-4" onClick={handleStatus}>
                <Button
                  text={"Create"}
                  color={color.white}
                  bgColor={color.primary}
                  fontSize={"15px"}
                  height={"45px"}
                  status={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
