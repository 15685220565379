import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Button from "../../components/Button";
import CompnaiesTable from "../../components/Companies/Tables/Companies";
import TopCards from "../../components/Companies/TopCards";
import Layout from "../../components/Layout";
import TopNav from "../../components/TopNav";
import { color } from "../../constant/color";
import axios from "../../utils/axios";
import { companyController } from "../../Controller/CompanyController";
import "./style.css";
import AddCompany from "../../components/Companies/AddCompany";
import MeterModelTable from "../../components/MeterModel/Tables/MeterModelTable";
import AddModel from "../../components/MeterModel/AddModel";

const Metermodel = () => {
  const [tabstate, settabstate] = useState(1);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [panels, setPanels] = useState(0);
  const [count, setCount] = useState(0);
  const [submeter, setSubmeter] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const controller = new AbortController();

  useEffect(() => {
    (async () => {
      try {
        let res = await axios.post(
          "/devicemanagement/submeter/getallmetermodels",
          {}
        );
        let result = companyController(res);

        if (result.type !== "success") {
          toast.error(result.message);
          setLoading(false);
          return;
        }
        setData(result.message.body);
        setPanels(
          result.message.body.filter((i) => i.devicetype == "Panel").length
        );
        setSubmeter(
          result.message.body.filter((i) => i.devicetype == "Submeter").length
        );
        setCount(result.message.meta.datapointcount);
        setLoading(false);
      } catch (err) {
        toast.err(err.message);
        setLoading(false);
      }
    })();

    return () => {
      controller.abort();
    };
  }, [refresh]);

  return (
    <Layout>
      <TopNav />
      <div className="mt-5  px-3">
        {modal && <AddModel closeBtn={setModal} refreshBtn={setRefresh} />}
        <div
          className="container-fluid"
          style={{
            marginBottom: 200,
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                title={"Meter models"}
                iconType={"company"}
                value={count}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                title={"Panels"}
                iconType={"activecompany"}
                value={panels}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <TopCards
                title={"Submeter"}
                iconType={"inactivecompany"}
                value={submeter}
              />
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-end justify-content-end">
              <div className="rounded" onClick={() => setModal(true)}>
                <Button
                  text={"Add metermodel"}
                  color={color.white}
                  fontSize={"15px"}
                  bgColor={color.primary}
                />
              </div>
            </div>
          </div>
          <div className="row mt-3 border bg-white shadow-sm rounded-2">
            <div className="col-12">
              <MeterModelTable data={data} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Metermodel;
