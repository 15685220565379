import React, { useState } from "react";
import SideNav from "../SideNav";
import "./style.css";

const Layout = ({ children }) => {
  return (
    <div className="d-flex align-items-start main-cover w-100">
      <div className="left-container px-2 py-3 ">
        <SideNav />
      </div>
      <div className="right-container w-100 ">{children}</div>
    </div>
  );
};

export default Layout;
