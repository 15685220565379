import { Link, useNavigate } from "react-router-dom";
import NotFoundImg from "../../assets/svg/404.gif";
import { color } from "../../constant/color";

const NotFound = () => {
  let navigate = useNavigate();
  return (
    <div className="backdrop">
      <div className="container bg-white rounded-2  flex-column  py-2 d-flex align-items-center justify-content-center">
        <div className="">
          <img src={NotFoundImg} className={"image-fluid"} />
        </div>
        <div className="p-2">Opps!!. You have visited an unknown page.</div>
        <div
          className=" text-decoration-underline"
          style={{
            color: color.primary,
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          Go back.
        </div>
      </div>
    </div>
  );
};

export default NotFound;
