import * as React from "react";
import moment from "moment";
import { color } from "../../../constant/color";
import { Link } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { RiDeleteBack2Fill } from "react-icons/ri";
import { CustomButton } from "../../CustomButton";
import { useDeleteDCUModelMutation } from "../../../services/dcumodel.services";
import toast from "react-hot-toast";
import { deviceController } from "../../../Controller/deviceController";
import { errorBlock } from "../../../Controller/errorBlock";
import { MdOutlineDeleteForever } from "react-icons/md";
import { tableCustomStyles } from "../../../HelperFunctions";
const DCUModelTable = ({ data, closeBtn, refreshBtn, loading }) => {
  const [deleteDCUModel, { isLoading, error, status }] =
    useDeleteDCUModelMutation();

  const handleDelete = async (data) => {
    try {
      let res = await deleteDCUModel(data).unwrap();
      const result = deviceController(res);

      if (result.type != "success") {
        toast.error("Failed to delete DCU.");
        return;
      }

      refreshBtn((prev) => !prev);
      closeBtn(false);
      toast.success("DCU model deleted successfully.");
    } catch (err) {
      console.log(err, "error");
      errorBlock(err);
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      center: true,
      grow: 2,
    },
    {
      name: "Model name",
      selector: (row) => row.devicemodel,
      sortable: true,
      center: true,
    },
    {
      name: "Decive capacity",
      selector: (row) => row.devicecapacity,
      sortable: true,
      center: true,
      grow: 3,
    },
    {
      name: "Date created",
      selector: (row) => moment(row.datecreated).format("lll"),
      sortable: true,
      grow: 3,
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <CustomButton
            loading={isLoading}
            onClick={() => handleDelete(row.devicemodel)}
            className={
              "w-100 border-none bg-danger outline-none border-0 text-white p-1 "
            }
          >
            <MdOutlineDeleteForever color={"white"} size={15} />
          </CustomButton>
        </div>
      ),
      button: true,
    },
  ];

  return (
    <div className="table-responsive">
      <DataTable
        columns={columns}
        data={data}
        pagination
        progressPending={loading}
        customStyles={tableCustomStyles}
      />
    </div>
  );
};

export default DCUModelTable;
