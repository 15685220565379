import { useState } from "react";

import { data } from "./data";
import "./style.css";
import { Select } from "antd";
import { GiMicrochip } from "react-icons/gi";
import CloseIcon from "../../../CloseIcon";
import { color } from "../../../../constant/color";
import AddSingle from "../../../Submeter/AddSingle";
import AddDCUSingle from "../AddDCUSingle";
import AddDCUMultiple from "../AddDCUMultiple";

const AddDCU = ({ closeModal, refreshBtn }) => {
  const [type, setType] = useState(false);
  const [removemodal, setRemoveModal] = useState(false);
  const Card = ({ item }) => {
    return (
      <div
        className="addsubmeter-cover border p-3  rounded-3 bg-white flex-column d-flex align-items-start justify-content-start"
        onClick={() => {
          if (item.id == 1) {
            setType("single");
          } else if (item.id == 3) {
            setType("multiple");
          } else if (item.id == 2) {
            setType("singleissue");
          } else if (item.id == 4) {
            setType("multipleissue");
          }
          setRemoveModal(true);
        }}
      >
        <div className="mt-2">
          <div className="addsubmeter-title">{item.title}</div>
          <div className="addsubmeter-desc">{item.desc}</div>
        </div>
      </div>
    );
  };
  return (
    <div className="backdrop">
      {type == "single" && (
        <AddDCUSingle closeBtn={closeModal} refreshBtn={refreshBtn} />
      )}
      {type == "multiple" && (
        <AddDCUMultiple closeBtn={closeModal} refreshBtn={refreshBtn} />
      )}
      {/* {type == "singleissue" && (
        <AddSingleAndIssue closeBtn={closeModal} refreshBtn={refreshBtn} />
      )}
      {type == "multipleissue" && (
        <AddMultipleAndIsssue closeBtn={closeModal} refreshBtn={refreshBtn} />
      )} */}

      <div
        className="submeter-modal_one p-3 animate__animated animate__fadeInUp bg-white shadow rounded"
        style={{
          display: removemodal ? "none" : "block",
        }}
      >
        <div
          className="text-left fw-bold mb-2"
          style={{
            fontSize: 15,
          }}
        >
          Select from any the options below <br /> to create a DCU
        </div>
        <div
          style={{
            position: "absolute",
            top: "8px",
            right: 20,
          }}
          onClick={() => closeModal(false)}
        >
          <CloseIcon />
        </div>

        <div className="container">
          <div className="row">
            {data.map((i) => (
              <div
                className="col-sm-12 mx-0 col-md-5 col-lg-12 col-xl-12 col-xxl-12 my-2"
                key={i.id}
              >
                <Card item={i} />
              </div>
            ))}
          </div>
          <div className="row"></div>
        </div>
      </div>
    </div>
  );
};

export default AddDCU;
