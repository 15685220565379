export const deviceController = (res) => {
  if (res.response == "failure") {
    return {
      type: "error",
    };
  } else {
    return {
      type: "success",
    };
  }
};

// remmber to handle the invalid session
